import React, { useEffect } from 'react';

import Modal from '../../../components/Modal';
import DeleteModal from '../../../components/DeleteModal';
import List from '../../../components/LibrariesList/index.js';
import ListMenu from '../../../components/LibrariesList/listMenu';
import Loader from '../../../components/Loader';

import Notifier from '../../../utils/Notifier';
import Families from './Families';

import AddLibrary from './addLibrary';
import AddCategory from './addCategory';
import AddWork from './addWork';
import EditWork from './editWork';

import AddWorkDetail from './addWorkDetail';
import EditWorkDetail from './editWorkDetail';

import EditLibrary from './editLibrary';
import EditCategory from './editCategory';

import Axios from 'axios';
import { baseUrl } from '../../../utils/BaseUrl';
import { getToken } from '../../../utils/Auth';
import LibraryReducer from './reducer';
import { initialState } from './initialState';
import actionTypes from './actionTypes';

export default function Index(props) {
  const { translate } = props;
  const token = getToken();
  const [state, dispatch] = React.useReducer(LibraryReducer, initialState);
  const [familiesData, setFamiliesData] = React.useState([]);
  const [currentFamily, setCurrentFamily] = React.useState('');
  const [librariesData, setlibrariesData] = React.useState([]);
  const [Level2, setLevel2] = React.useState([]);
  const [Level3, setLevel3] = React.useState([]);
  const [Level4, setLevel4] = React.useState([]);
  const [LevelID, setLevelID] = React.useState('');
  const [Index, setIndex] = React.useState('');

  const [LibraryID, setLibraryID] = React.useState('');
  const [LevelData, setLevelData] = React.useState([]);
  const [parentFamily, setFarentFamily] = React.useState([]);
  const [Level, setLevel] = React.useState('');
  const [parentLevel, setparentLevel] = React.useState(false);
  const [Text, setText] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [loadingData, setLoadingData] = React.useState('');

  const openanchorEl = Boolean(state.anchorEl);
  const handleanchorEl = (event, id, level) => {
    // setAnchorEl(event.currentTarget);
    dispatch({
      type: actionTypes.anchorEl,
      anchorEl: event.currentTarget
    });
    setLevel(level);

    setLevelID(id);
    switch (level) {
      case 'level0':
        setText('Add_Library');
        setparentLevel(true);
        break;
      case 'level1':
        setText('Add_Category');
        setparentLevel(false);
        break;
      case 'level2':
        setText('Add_Work');
        setparentLevel(false);
        break;
      case 'level3':
        setText('Add_Work_Detail');
        setparentLevel(false);
        break;
      default:
        setparentLevel(false);
        setText('');
        break;
    }
  };

  const handleanchorElClose = () => {
    dispatch({
      type: actionTypes.anchorEl,
      anchorEl: null
    });
  };
  const handleClick = (level, id, obj) => {
    dispatch({
      type: level,
      obj
    });
    if (level === 'level1') {
      setLibraryID(id);
      handleLibraryData(id);
    }
  };
  const handleLibraryData = id => {
    let filteredData = handleEditLibrary(id);

    setLevelData(filteredData);
  };

  const handleModalClick = value => {
    if (Level == actionTypes.LEVEL_0) {
      switch (value) {
        case `Add_Library_${Level}`:
          setFarentFamily([LevelID]);
          dispatch({
            type: actionTypes.ADD_LIBRARY,
            addlibrary: true
          });

          break;
        case `Manage_Families_${Level}`:
          dispatch({
            type: actionTypes.MANAGE_FAMILIES,
            managefamily: true
          });

          break;
        default:
          break;
      }
    } else if (Level == actionTypes.LEVEL_1) {
      switch (value) {
        case `Add_Category_${Level}`:
          dispatch({
            type: actionTypes.ADD_CATEGORY,
            addcategory: true
          });
          break;
        case `Edit_${Level}`:
          let filteredData = handleEditLibrary(LevelID);
          dispatch({
            type: actionTypes.EDIT_LIBRARY,
            editlibrary: true,
            editLibraryData: filteredData,
            editlibraryId: LevelID
          });
          break;
        case `Delete_${Level}`:
          dispatch({
            type: actionTypes.DELETE_LIBRARY,
            deletelibrary: true,
            libraryDeleteId: LevelID
          });
          break;

        case `Top_${Level}`:
          handleLibraryTop(LevelID);
          break;
        case `Up_${Level}`:
          handleLibraryUp(LevelID);
          break;
        case `Down_${Level}`:
          handleLibraryDown(LevelID);
          break;
        case `Bottom_${Level}`:
          handleLibraryBottom(LevelID);
          break;
        default:
          break;
      }
    } else if (Level == actionTypes.LEVEL_2) {
      switch (value) {
        case `Add_Work_${Level}`:
          dispatch({
            type: actionTypes.ADD_WORK,
            addwork: true
          });
          break;
        case `Edit_${Level}`:
          let filteredData = handleEditCategory(LevelID);
          dispatch({
            type: actionTypes.EDIT_CATEGORY,
            editcategory: true,
            editCategoryData: filteredData,
            editcategoryId: LevelID
          });
          break;
        case `Delete_${Level}`:
          dispatch({
            type: actionTypes.DELETE_CATEGORY,
            deletecategory: true,
            categoryDeleteId: LevelID
          });
          break;

        case `Top_${Level}`:
          handleCategoryTop(LevelID);
          break;
        case `Up_${Level}`:
          handleCategoryUp(LevelID);
          break;
        case `Down_${Level}`:
          handleCategoryDown(LevelID);
          break;
        case `Bottom_${Level}`:
          handleCategoryBottom(LevelID);
          break;
        default:
          break;
      }
    } else if (Level == actionTypes.LEVEL_3) {
      switch (value) {
        case `Add_Work_Detail_${Level}`:
          dispatch({
            type: actionTypes.ADD_WORK_DETAIL,
            addworkdetail: true
          });
          break;
        case `Edit_${Level}`:
          let filteredData = handleEditWork(LevelID);

          dispatch({
            type: actionTypes.EDIT_WORK,
            editwork: true,
            editWorkData: filteredData,
            editworkId: LevelID
          });
          break;
        case `Delete_${Level}`:
          dispatch({
            type: actionTypes.DELETE_WORK,
            deletework: true,
            workDeleteId: LevelID
          });
          break;
        case `Top_${Level}`:
          handleWorkTop(LevelID);
          break;
        case `Up_${Level}`:
          handleWorkUp(LevelID);
          break;
        case `Down_${Level}`:
          handleWorkDown(LevelID);
          break;
        case `Bottom_${Level}`:
          handleWorkBottom(LevelID);
          break;
        default:
          break;
      }
    } else if (Level == actionTypes.LEVEL_4) {
      switch (value) {
        case `Edit_${Level}`:
          let filteredData = handleEditWorkDetail(LevelID);

          dispatch({
            type: actionTypes.EDIT_WORK_DETAIL,
            editworkdetail: true,
            editWorkDetailData: filteredData,
            editworkdetailId: LevelID
          });
          break;
        case `Delete_${Level}`:
          dispatch({
            type: actionTypes.DELETE_WORK_DETAIL,
            deleteworkdetail: true,
            workDetailDeleteId: LevelID
          });
          break;
        case `Top_${Level}`:
          handleWorkDetailTop(LevelID);
          break;
        case `Up_${Level}`:
          handleWorkDetailUp(LevelID);
          break;
        case `Down_${Level}`:
          handleWorkDetailDown(LevelID);
          break;
        case `Bottom_${Level}`:
          handleWorkDetailBottom(LevelID);
          break;
        default:
          break;
      }
    }
  };
  const handleClose = () => {
    dispatch({
      type: actionTypes.CLOSE_MODAL,
      addlibrary: false,
      editlibrary: false,
      deletelibrary: false,
      // category
      addcategory: false,
      editcategory: false,
      deletecategory: false,
      //work
      addwork: false,
      editwork: false,
      deletework: false,
      //work detail
      addworkdetail: false,
      editworkdetail: false,
      deleteworkdetail: false,
      managefamily: false
    });
  };

  //LibraryTop
  const handleLibraryTop = async id => {
    let filteredIndex = librariesData.findIndex(dt => dt._id == id);

    if (filteredIndex != 0) {
      let minRanking = Math.min.apply(
        Math,
        librariesData.map(item => {
          return item.ranking;
        })
      );
      Axios({
        method: 'put',
        url: `${baseUrl}/BookLibraries/${id}`,
        data: { ranking: minRanking - 1 },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(() => {
        let tempArr = [...librariesData];
        tempArr[filteredIndex].ranking = minRanking - 1;
        tempArr = tempArr.sort((a, b) => a.ranking - b.ranking);
        handleClose();
        setlibrariesData(tempArr);
      });
    }
  };

  //LibraryUp
  const handleLibraryUp = async id => {
    // setIndex(id);
    let filteredIndex = librariesData.findIndex(dt => dt._id == id);
    if (filteredIndex != 0) {
      let tempArr = [...librariesData];
      let prevIndex = filteredIndex - 1;

      let prev = tempArr[prevIndex];

      Axios({
        method: 'put',
        url: `${baseUrl}/BookLibraries/${id}`,
        data: { ranking: prevIndex },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(() => {
        Axios({
          method: 'put',
          url: `${baseUrl}/BookLibraries/${prev._id}`,
          data: { ranking: filteredIndex },
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then(() => {
          tempArr[filteredIndex] = librariesData[filteredIndex - 1];
          tempArr[filteredIndex - 1] = librariesData[filteredIndex];
          setlibrariesData(tempArr);
        });
      });
    }
  };
  //Library Down
  const handleLibraryDown = async id => {
    // setIndex(id);
    let filteredIndex = librariesData.findIndex(dt => dt._id == id);

    if (filteredIndex !== librariesData.length - 1) {
      let tempArr = [...librariesData];
      let nextIndex = filteredIndex + 1;
      let prev = tempArr[nextIndex];

      Axios({
        method: 'put',
        url: `${baseUrl}/BookLibraries/${id}`,
        data: { ranking: nextIndex },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(() => {
        Axios({
          method: 'put',
          url: `${baseUrl}/BookLibraries/${prev._id}`,
          data: { ranking: filteredIndex },
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then(() => {
          tempArr[filteredIndex] = librariesData[nextIndex];
          tempArr[nextIndex] = librariesData[filteredIndex];
          setlibrariesData(tempArr);
        });
      });
    }
  };

  // Library Bottom
  const handleLibraryBottom = async id => {
    let filteredIndex = librariesData.findIndex(dt => dt._id == id);

    if (filteredIndex !== librariesData.length - 1) {
      let maxRanking = Math.max.apply(
        Math,
        librariesData.map(item => {
          return item.ranking;
        })
      );
      Axios({
        method: 'put',
        url: `${baseUrl}/BookLibraries/${id}`,
        data: { ranking: maxRanking + 1 },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(() => {
        let tempArr = [...librariesData];
        tempArr[filteredIndex].ranking = maxRanking + 1;
        tempArr = tempArr.sort((a, b) => a.ranking - b.ranking);
        handleClose();
        setlibrariesData(tempArr);
      });
    }
  };

  //Category Top
  const handleCategoryTop = async id => {
    let categories = Level2;
    let filteredIndex = categories.findIndex(dt => dt._id == id);

    if (filteredIndex != 0) {
      let minRanking = Math.min.apply(
        Math,
        categories.map(item => {
          return item.ranking;
        })
      );
      Axios({
        method: 'put',
        url: `${baseUrl}/BookLibraries/editCategory/${id}`,
        data: { ranking: minRanking - 1 },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(() => {
        let tempArr = [...categories];
        tempArr[filteredIndex].ranking = minRanking - 1;
        tempArr = tempArr.sort((a, b) => a.ranking - b.ranking);
        handleClose();
        setLevel2(tempArr);
      });
    }
  };

  //categoryUp
  const handleCategoryUp = async id => {
    // setIndex(id);
    let categories = Level2;
    let filteredIndex = categories.findIndex(dt => dt._id == id);

    if (filteredIndex !== 0) {
      let tempArr = [...categories];
      let prevIndex = filteredIndex - 1;

      let prev = tempArr[prevIndex];
      Axios({
        method: 'put',
        url: `${baseUrl}/BookLibraries/editCategory/${id}`,
        data: { ranking: prevIndex },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(() => {
        Axios({
          method: 'put',
          url: `${baseUrl}/BookLibraries/editCategory/${prev._id}`,
          data: { ranking: filteredIndex },
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then(() => {
          handleanchorElClose();
          getLevel2(Level2[0].book_library);
        });
      });
    }
  };
  //categoryDown
  const handleCategoryDown = async id => {
    // setIndex(id);
    let categories = Level2;
    let filteredIndex = categories.findIndex(dt => dt._id == id);

    if (filteredIndex !== categories.length - 1) {
      let tempArr = [...categories];
      let prevIndex = filteredIndex + 1;

      let prev = tempArr[prevIndex];

      Axios({
        method: 'put',
        url: `${baseUrl}/BookLibraries/editCategory/${id}`,
        data: { ranking: prevIndex },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(() => {
        Axios({
          method: 'put',
          url: `${baseUrl}/BookLibraries/editCategory/${prev._id}`,
          data: { ranking: filteredIndex },
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then(() => {
          handleanchorElClose();
          getLevel2(Level2[0].book_library);
        });
      });
    }
  };

  // Category Bottom
  const handleCategoryBottom = async id => {
    let categories = Level2;
    let filteredIndex = categories.findIndex(dt => dt._id == id);

    if (filteredIndex !== categories.length - 1) {
      let maxRanking = Math.max.apply(
        Math,
        categories.map(item => {
          return item.ranking;
        })
      );
      Axios({
        method: 'put',
        url: `${baseUrl}/BookLibraries/editCategory/${id}`,
        data: { ranking: maxRanking + 1 },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(() => {
        let tempArr = [...categories];
        tempArr[filteredIndex].ranking = maxRanking + 1;
        tempArr = tempArr.sort((a, b) => a.ranking - b.ranking);
        handleClose();
        setLevel2(tempArr);
      });
    }
  };

  //Work Top
  const handleWorkTop = async id => {
    let works = Level3;
    let filteredIndex = works.findIndex(dt => dt._id == id);

    if (filteredIndex != 0) {
      let minRanking = Math.min.apply(
        Math,
        works.map(item => {
          return item.ranking;
        })
      );
      Axios({
        method: 'put',
        url: `${baseUrl}/BookLibraries/editWork/${id}`,
        data: { ranking: minRanking - 1 },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(() => {
        let tempArr = [...works];
        tempArr[filteredIndex].ranking = minRanking - 1;
        tempArr = tempArr.sort((a, b) => a.ranking - b.ranking);
        handleClose();
        setLevel3(tempArr);
      });
    }
  };

  //workUp
  const handleWorkUp = async id => {
    // setIndex(id);

    let works = Level3;
    let filteredIndex = works.findIndex(dt => dt._id == id);

    if (filteredIndex !== 0) {
      let tempArr = [...works];
      let prevIndex = filteredIndex - 1;

      let prev = tempArr[prevIndex];

      Axios({
        method: 'put',
        url: `${baseUrl}/BookLibraries/editWork/${id}`,
        data: { ranking: prevIndex },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(() => {
        Axios({
          method: 'put',
          url: `${baseUrl}/BookLibraries/editWork/${prev._id}`,
          data: { ranking: filteredIndex },
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then(() => {
          handleanchorElClose();
          getLevel3(Level3[0].category);
        });
      });
    }
  };
  //workDown
  const handleWorkDown = async id => {
    // setIndex(id);
    let works = Level3;
    let filteredIndex = works.findIndex(dt => dt._id == id);

    if (filteredIndex !== works.length - 1) {
      let tempArr = [...works];
      let prevIndex = filteredIndex + 1;

      let prev = tempArr[prevIndex];

      Axios({
        method: 'put',
        url: `${baseUrl}/BookLibraries/editWork/${id}`,
        data: { ranking: prevIndex },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(() => {
        Axios({
          method: 'put',
          url: `${baseUrl}/BookLibraries/editWork/${prev._id}`,
          data: { ranking: filteredIndex },
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then(() => {
          handleanchorElClose();
          getLevel3(Level3[0].category);
        });
      });
    }
  };
  // Work Bottom
  const handleWorkBottom = async id => {
    let works = Level3;
    let filteredIndex = works.findIndex(dt => dt._id == id);

    if (filteredIndex !== works.length - 1) {
      let maxRanking = Math.max.apply(
        Math,
        works.map(item => {
          return item.ranking;
        })
      );
      Axios({
        method: 'put',
        url: `${baseUrl}/BookLibraries/editWork/${id}`,
        data: { ranking: maxRanking + 1 },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(() => {
        let tempArr = [...works];
        tempArr[filteredIndex].ranking = maxRanking + 1;
        tempArr = tempArr.sort((a, b) => a.ranking - b.ranking);
        handleClose();
        setLevel3(tempArr);
      });
    }
  };

  //handleWorkDetailTop
  const handleWorkDetailTop = async id => {
    let work_details = Level4;
    let filteredIndex = work_details.findIndex(dt => dt._id == id);
    if (filteredIndex != 0) {
      let minRanking = Math.min.apply(
        Math,
        work_details.map(item => {
          return item.ranking;
        })
      );
      Axios({
        method: 'put',
        url: `${baseUrl}/BookLibraries/editWorkDetail/${id}`,
        data: { ranking: minRanking - 1 },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(() => {
        let tempArr = [...work_details];
        tempArr[filteredIndex].ranking = minRanking - 1;
        tempArr = tempArr.sort((a, b) => a.ranking - b.ranking);
        handleClose();
        setLevel4(tempArr);
      });
    }
  };

  //workdetailUp
  const handleWorkDetailUp = async id => {
    // setIndex(id);
    let work_details = Level4;
    let filteredIndex = work_details.findIndex(dt => dt._id == id);

    if (filteredIndex !== 0) {
      let tempArr = [...work_details];
      let prevIndex = filteredIndex - 1;

      let prev = tempArr[prevIndex];

      Axios({
        method: 'put',
        url: `${baseUrl}/BookLibraries/editWorkDetail/${id}`,
        data: { ranking: prevIndex },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(() => {
        Axios({
          method: 'put',
          url: `${baseUrl}/BookLibraries/editWorkDetail/${prev._id}`,
          data: { ranking: filteredIndex },
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then(() => {
          handleanchorElClose();
          getLevel4(Level4[0].work);
        });
      });
    }
  };
  //workdetailDown
  const handleWorkDetailDown = async id => {
    // setIndex(id);
    let work_details = Level4;
    let filteredIndex = work_details.findIndex(dt => dt._id == id);

    if (filteredIndex !== work_details.length - 1) {
      let tempArr = [...work_details];
      let prevIndex = filteredIndex + 1;

      let prev = tempArr[prevIndex];

      Axios({
        method: 'put',
        url: `${baseUrl}/BookLibraries/editWorkDetail/${id}`,
        data: { ranking: prevIndex },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(() => {
        Axios({
          method: 'put',
          url: `${baseUrl}/BookLibraries/editWorkDetail/${prev._id}`,
          data: { ranking: filteredIndex },
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then(() => {
          handleanchorElClose();
          getLevel4(Level4[0].work);
        });
      });
    }
  };

  // workdetail Bottom
  const handleWorkDetailBottom = async id => {
    let work_details = Level4;
    let filteredIndex = work_details.findIndex(dt => dt._id == id);

    if (filteredIndex !== work_details.length - 1) {
      let maxRanking = Math.max.apply(
        Math,
        work_details.map(item => {
          return item.ranking;
        })
      );
      Axios({
        method: 'put',
        url: `${baseUrl}/BookLibraries/editWorkDetail/${id}`,
        data: { ranking: maxRanking + 1 },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(() => {
        let tempArr = [...work_details];
        tempArr[filteredIndex].ranking = maxRanking + 1;
        tempArr = tempArr.sort((a, b) => a.ranking - b.ranking);
        handleClose();
        setLevel4(tempArr);
      });
    }
  };

  //delete library
  const handleDeleteLibrary = async () => {
    try {
      let response = await Axios({
        method: 'delete',
        url: `${baseUrl}/BookLibraries/${state.libraryDeleteId}`,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      getBookLibraries(currentFamily);
      handleClose();
      Notifier(response.data.message, 'success', translate);
    } catch (error) {
      if (error.response) Notifier(error.response.data.message, 'error ', translate);
    }
  };
  //delete Category
  const handleDeleteCategory = async () => {
    try {
      let response = await Axios({
        method: 'delete',
        url: `${baseUrl}/BookLibraries/deleteCategory/${state.categoryDeleteId}`,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      getLevel2(response?.data?.Category?.book_library);
      handleClose();
      Notifier(response.data.message, 'success', translate);
    } catch (error) {
      if (error.response) Notifier(error.response.data.message, 'error ', translate);
    }
  };
  //delete Work
  const handleDeleteWork = async () => {
    try {
      let response = await Axios({
        method: 'delete',
        url: `${baseUrl}/BookLibraries/deleteWork/${state.workDeleteId}`,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      getLevel3(response?.data?.Work?.category);
      handleClose();
      Notifier(response.data.message, 'success', translate);
    } catch (error) {
      if (error.response) Notifier(error.response.data.message, 'error ', translate);
    }
  };
  //delete Work Detail
  const handleDeleteWorkDetail = async () => {
    try {
      let response = await Axios({
        method: 'delete',
        url: `${baseUrl}/BookLibraries/deleteWorkDetail/${state.workDetailDeleteId}`,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      getLevel4(response?.data?.WorkDetail?.work);
      handleClose();
      Notifier(response.data.message, 'success', translate);
    } catch (error) {
      if (error.response) Notifier(error.response.data.message, 'error ', translate);
    }
  };
  const handleEditLibrary = id => {
    let filteredData;
    if (id) {
      filteredData = librariesData.find(data => data._id == id);
    }
    return filteredData;
  };

  const handleEditCategory = id => {
    let filteredData;
    if (id) {
      filteredData = Level2.find(data => data._id == id);
    }
    return filteredData;
  };

  const handleEditWork = id => {
    let filteredData;
    if (id) {
      filteredData = Level3.find(data => data._id == id);
    }

    return filteredData;
  };
  const handleEditWorkDetail = id => {
    let filteredData;
    if (id) {
      filteredData = Level4.find(data => data._id == id);
    }

    return filteredData;
  };
  const getBookLibraries = async id => {
    try {
      let response = await Axios({
        method: 'get',
        url: `${baseUrl}/BookLibraries/getByFamily/${id}`,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      let data = response.data.bookLibraries.sort((a, b) => a.ranking - b.ranking);
      setlibrariesData(data);
      setLoadingData('');
    } catch (error) {
      if (error.response) console.log(error.response.data.message, 'error ');
      setLoadingData('');
    }
    //}
  };

  const getLevel2 = async id => {
    try {
      let response = await Axios({
        method: 'get',
        url: `${baseUrl}/BookLibraries/getLevel2/${id}`,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      let data = response.data.categories.sort((a, b) => a.ranking - b.ranking);
      setLevel2(data);
      setLoadingData('');
    } catch (error) {
      if (error.response) console.log(error.response.data.message, 'error ');
      setLoadingData('');
    }
  };

  const getLevel3 = async id => {
    try {
      let response = await Axios({
        method: 'get',
        url: `${baseUrl}/BookLibraries/getLevel3/${id}`,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      let data = response.data.works.sort((a, b) => a.ranking - b.ranking);
      setLevel3(data);
      setLoadingData('');
    } catch (error) {
      if (error.response) console.log(error.response.data.message, 'error ');
      setLoadingData('');
    }
  };

  const getLevel4 = async id => {
    try {
      let response = await Axios({
        method: 'get',
        url: `${baseUrl}/BookLibraries/getLevel4/${id}`,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      let data = response.data.workDetails.sort((a, b) => a.ranking - b.ranking);
      setLevel4(data);
      setLoadingData('');
    } catch (error) {
      if (error.response) console.log(error.response.data.message, 'error ');
      setLoadingData('');
    }
  };

  const getFamilies = async () => {
    try {
      let response = await Axios({
        method: 'get',
        url: `${baseUrl}/Families/`,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      let data = response.data.families.sort((a, b) => a.ranking - b.ranking);
      data = data.filter(item => {
        let famName = item.name.toUpperCase();
        return famName !== 'PRESCRIPTEUR';
      });
      setFamiliesData(data);
      setLoading(false);
    } catch (error) {
      if (error.response) console.log(error.response.data.message, 'error ');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (LibraryID) {
      handleLibraryData(LibraryID);
    }
  }, [state, Level]);

  useEffect(() => {
    getFamilies();
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <>
      <ListMenu
        onClick={handleanchorEl}
        onClose={handleanchorElClose}
        onAdd={handleModalClick}
        open={openanchorEl}
        anchorEl={state.anchorEl}
        text={Text}
        level={Level}
        id={'demo-customized-button'}
        parentLevel={parentLevel}
        translate={translate}
        {...props}
      />

      <div className="w-full  md:mx-auto py-20 ">
        <div className="container mx-auto px-6">
          <div className="w-3/4 mx-auto ">
            <List
              translate={translate}
              state={state}
              familiesData={familiesData}
              librariesData={librariesData}
              handleClick={handleClick}
              parentLevel={parentLevel}
              handleanchorEl={handleanchorEl}
              getLevel2={getLevel2}
              Level2={Level2}
              getLevel3={getLevel3}
              Level3={Level3}
              getLevel4={getLevel4}
              Level4={Level4}
              getBookLibraries={getBookLibraries}
              setlibrariesData={setlibrariesData}
              setLevel2={setLevel2}
              setLevel3={setLevel3}
              setLevel4={setLevel4}
              setCurrentFamily={setCurrentFamily}
              loadingData={loadingData}
              setLoadingData={setLoadingData}
            />
          </div>
        </div>
      </div>
      <Modal
        title="Add Library"
        height="60%"
        library={true}
        translate={translate}
        open={state.addlibrary}
        component={
          <AddLibrary
            librariesData={librariesData}
            parentFamily={parentFamily}
            getBookLibraries={getBookLibraries}
            translate={translate}
            handleClose={handleClose}
          />
        }
      />
      <Modal
        title={translate('Add Category')}
        // width="50%"
        height="65%"
        library={true}
        open={state.addcategory}
        translate={translate}
        component={
          <AddCategory
            translate={translate}
            handleClose={handleClose}
            BookLibraryId={LevelID}
            getLevel2={getLevel2}
          />
        }
      />
      <Modal
        title="Add Work"
        width="70%"
        addWork={true}
        library={true}
        open={state.addwork}
        translate={translate}
        component={
          <AddWork
            translate={translate}
            handleClose={handleClose}
            categoryId={LevelID}
            getLevel3={getLevel3}
            works={Level3}
          />
        }
      />
      <Modal
        title={translate('Add Work detail')}
        open={state.addworkdetail}
        translate={translate}
        width="30%"
        library={true}
        component={
          <AddWorkDetail
            translate={translate}
            handleClose={handleClose}
            workId={LevelID}
            getLevel4={getLevel4}
          />
        }
      />
      <Modal
        title={translate('Edit Library')}
        height="60%"
        translate={translate}
        open={state.editlibrary}
        library={true}
        component={
          <EditLibrary
            translate={translate}
            editData={state.editLibraryData}
            editmodal={state.editlibrary}
            handleClose={handleClose}
            libraryId={state.editlibraryId}
            getBookLibraries={getBookLibraries}
          />
        }
      />
      <Modal
        title="Edit Category"
        open={state.editcategory}
        translate={translate}
        library={true}
        component={
          <EditCategory
            translate={translate}
            editData={state.editCategoryData}
            editmodal={state.editcategory}
            handleClose={handleClose}
            libraryId={state.editcategoryId}
            getLevel2={getLevel2}
          />
        }
      />
      <Modal
        title={translate('Edit Work')}
        open={state.editwork}
        translate={translate}
        library={true}
        addWork={true}
        width="70%"
        height="98%"
        left="50%"
        component={
          <EditWork
            translate={translate}
            editData={state.editWorkData}
            editmodal={state.editwork}
            handleClose={handleClose}
            libraryId={state.editworkId}
            getLevel3={getLevel3}
          />
        }
      />
      <Modal
        title={translate('Edit Work Detail')}
        open={state.editworkdetail}
        width="30%"
        translate={translate}
        library={true}
        component={
          <EditWorkDetail
            translate={translate}
            editData={state.editWorkDetailData}
            editmodal={state.editworkdetail}
            handleClose={handleClose}
            libraryId={state.editworkdetailId}
            getLevel4={getLevel4}
          />
        }
      />
      <Modal
        open={state.deletelibrary}
        delete={true}
        component={
          <DeleteModal
            translate={translate}
            handleClose={handleClose}
            handleDelete={handleDeleteLibrary}
          />
        }
      />
      <Modal
        open={state.deletecategory}
        delete={true}
        component={
          <DeleteModal
            translate={translate}
            handleClose={handleClose}
            handleDelete={handleDeleteCategory}
          />
        }
      />
      <Modal
        open={state.deletework}
        delete={true}
        component={
          <DeleteModal
            translate={translate}
            handleClose={handleClose}
            handleDelete={handleDeleteWork}
          />
        }
      />
      <Modal
        open={state.deleteworkdetail}
        delete={true}
        component={
          <DeleteModal
            translate={translate}
            handleClose={handleClose}
            handleDelete={handleDeleteWorkDetail}
          />
        }
      />
    </>
  );
}
