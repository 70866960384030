export const LOGIN_STATE = {
  email: '',
  password: '',
};
export const ARCHITECT_STATE = {
  address_1: '',
  address_2: '',
  po_box: '',
  postal_code: '',
  city: '',
  email_address_client: '',
  phone: '',
  client_code: '',
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  confirm_password: '',
  establishment: '',
  service: '',
  authorized: 'No',
};
export const ADMIN_STATE = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  confirm_password: '',
};

export const UPDATE_PROFILE_STATE = {
  first_name: '',
  last_name: '',
  email: '',
  oldPassword: '',
  newPassword: '',
};

export const PRODUCT_STATE = {
  name: '',
  type: 'Prescribers',
  duration: 0,
  projects: 0,
  price: '0.00',
  reference: '',
};

export const SUPPORT_STATE = {
  title: '',
};

export const FOLDER_STATE = {
  name: '',
};

export const PROVIDER_STATE = {
  firstname: '',
  name: '',
  email: '',
  telephone_no: '',
  contact_no: '',
  address1: '',
  address2: '',
  city: '',
  zipcode: '',
  url: '',
  logo: '',
  po_box: '',
  postal_code: '',
  email_address_client: '',
  client_code: '',
  establishment: '',
  authorized: 'No',
};
export const LIBRARY_STATE = {
  name: '',
  cctp_text: '',
  families: [],
};
export const CATEGORY_STATE = {
  name: '',
  cctp_text: '',
  unit: '',
};
export const WORK_STATE = {
  name: '',
  cctp_text: '',
  bpu_text: '',
  unit: '',
  unit_price: '0.00',
  providers: '',
};
export const WORK_DETAIL_STATE = {
  name: '',
  unit: '',
  unit_price: '0.00',
};
export const UNIT_STATE = {
  name: '',
  symbol: '',
  ranking: '',
};

export const VAT_STATE = {
  value: '',
  ranking: '',
};

export const CCTP_STATE = {
  name: '',
  type: '',
  ranking: '',
};

export const FAMILIES_STATE = {
  name: '',
  ranking: '',
};

export const CHAP0_STATE = {
  title: '',
  text: '',
  ranking: 0,
};

export const CHAP0_Level2_STATE = {
  title: '',
  text: '',
  ranking: 0,
  parentLevel: '',
};
export const CHAP0_Level3_STATE = {
  title: '',
  text: '',
  ranking: 0,
  parentLevel: '',
};
export const STAKEHOLDER_STATE = {
  text: '',
  ranking: '',
};
