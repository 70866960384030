import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import FontAwesomeIcon from '../FontAwesomeIcon';
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'tp',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export default function CustomizedMenus(props) {
  const { level, parentLevel, onAdd, translate, state } = props;

  const handleClick = (text) => {
    let concatText = text + '_' + level;
    onAdd(concatText);
  };

  if (parentLevel == true) {
    return (
      <div>
        <StyledMenu
          id={props.id}
          anchorEl={props.anchorEl}
          open={props.open}
          onClose={props.onClose}
          MenuListProps={{
            'aria-labelledby': props.id,
          }}
        >
          <div style={{ paddingLeft: '0.6rem' }}>
            {props.text && (
              <>
                <MenuItem
                  onClick={() => {
                    handleClick(props.text);
                    props.onClose();
                  }}
                >
                  {translate('Add Chap0Level1')}
                </MenuItem>
                {/* <MenuItem onClick={() => handleClick("Manage_Families")}>
                  Manage Families
                </MenuItem> */}
              </>
            )}
          </div>
        </StyledMenu>
      </div>
    );
  }
  return (
    <div>
      <StyledMenu
        id={props.id}
        anchorEl={props.anchorEl}
        open={props.open}
        onClose={props.onClose}
        MenuListProps={{
          'aria-labelledby': props.id,
        }}
      >
        <MenuItem
          onClick={() => {
            handleClick('Edit');
            props.onClose();
          }}
          disableRipple
        >
          <FontAwesomeIcon sx={{ fontSize: '1rem' }} icon={faEdit} />
          {translate('Edit')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClick('Delete');
            props.onClose();
          }}
          disableRipple
        >
          <FontAwesomeIcon sx={{ fontSize: '1rem' }} icon={faTrash} />
          {translate('Delete')}
        </MenuItem>
        {/* <Divider sx={{ my: 0.5 }} /> */}
        <div style={{ paddingLeft: '0.6rem' }}>
          <MenuItem
            onClick={() => {
              handleClick('Top');
              props.onClose();
            }}
            disableRipple
          >
            {translate('Top')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClick('Up');
              props.onClose();
            }}
            disableRipple
          >
            {translate('Up')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClick('Down');
              props.onClose();
            }}
            disableRipple
          >
            {translate('Down')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClick('Bottom');
              props.onClose();
            }}
            disableRipple
          >
            {translate('Bottom')}
          </MenuItem>
          {props.text && (
            <>
              <Divider sx={{ my: 0.5 }} />
              <MenuItem
                onClick={() => {
                  console.log('innn');
                  handleClick(props.text);
                  props.onClose();
                }}
              >
                {translate(props.text.replace('_', ' '))}
              </MenuItem>
            </>
          )}
        </div>
      </StyledMenu>
    </div>
  );
}
