import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import SidebarLinkGroup from './SidebarLinkGroup';
// import Logo from '../images/Logo_QBSoft_12.png';
import Logo from '../images/qlogo.svg';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import InventoryIcon from '@mui/icons-material/Inventory';
import CloudIcon from '@mui/icons-material/Cloud';

function Sidebar({ sidebarOpen, setSidebarOpen, translate }) {
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
  );

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target))
        return;
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector('body').classList.add('sidebar-expanded');
    } else {
      document.querySelector('body').classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:${
          !sidebarExpanded ? `sidebar-expanded:` : ``
        }!w-64 2xl:!w-64 flex-shrink-0 bg-gray-800 p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-64'
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-gray-500 hover:text-gray-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <NavLink
            exact
            to="/admin/index"
            className="block w-full flex justify-center -mb-4 mt-2.5"
          >
            <img className="w-20" src={Logo} alt="Logo" />
          </NavLink>
        </div>

        {/* Links */}
        <div className="space-y-8">
          {/* Pages group */}
          <div>
            <h3 className="text-xs uppercase text-gray-500 font-semibold pl-3">
              <span
                className={`hidden lg:block lg:${
                  !sidebarExpanded ? `sidebar-expanded:` : ``
                }hidden 2xl:hidden text-center w-6`}
                aria-hidden="true"
              >
                •••
              </span>
              <span
                className={`lg:hidden lg:${
                  !sidebarExpanded ? `sidebar-expanded:` : ``
                }block 2xl:block`}
              >
                {translate('Pages')}
              </span>
            </h3>
            <ul className="mt-3">
              {/* Dashboard */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname === '/' && 'bg-gray-900'
                }`}
              >
                <NavLink
                  exact
                  to="/admin/index"
                  className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                    pathname === '/' && 'hover:text-gray-200'
                  }`}
                >
                  <div className="flex items-center">
                    <DashboardIcon className="text-gray-400" />
                    <span
                      className={`text-sm font-medium ml-3 lg:opacity-0 lg:${
                        !sidebarExpanded ? `sidebar-expanded:` : ``
                      }opacity-100 2xl:opacity-100 duration-200`}
                    >
                      {translate('Dashboard')}
                    </span>
                  </div>
                </NavLink>
              </li>
              {/* Admins */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname === '/' && 'bg-gray-900'
                }`}
              >
                <NavLink
                  exact
                  to="/admin/admins"
                  className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                    pathname === '/' && 'hover:text-gray-200'
                  }`}
                >
                  <div className="flex items-center">
                    <svg className="flex-shrink-0 h-6 w-6" viewBox="0 0 24 24">
                      <path
                        className={`fill-current text-gray-600 ${
                          pathname.includes('team') && 'text-indigo-500'
                        }`}
                        d="M18.974 8H22a2 2 0 012 2v6h-2v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5h-2v-6a2 2 0 012-2h.974zM20 7a2 2 0 11-.001-3.999A2 2 0 0120 7zM2.974 8H6a2 2 0 012 2v6H6v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v-6a2 2 0 012-2h.974zM4 7a2 2 0 11-.001-3.999A2 2 0 014 7z"
                      />
                      <path
                        className={`fill-current text-gray-400 ${
                          pathname.includes('team') && 'text-indigo-300'
                        }`}
                        d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z"
                      />
                    </svg>
                    <span
                      className={`text-sm font-medium ml-3 lg:opacity-0 lg:${
                        !sidebarExpanded ? `sidebar-expanded:` : ``
                      }opacity-100 2xl:opacity-100 duration-200`}
                    >
                      {translate('Admins')}
                    </span>
                  </div>
                </NavLink>
              </li>
              {/* Architects */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname === '/' && 'bg-gray-900'
                }`}
              >
                <NavLink
                  exact
                  to="/admin/architects"
                  className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                    pathname === '/' && 'hover:text-gray-200'
                  }`}
                >
                  <div className="flex items-center">
                    <svg className="flex-shrink-0 h-6 w-6" viewBox="0 0 24 24">
                      <path
                        className={`fill-current text-gray-600 ${
                          pathname.includes('team') && 'text-indigo-500'
                        }`}
                        d="M18.974 8H22a2 2 0 012 2v6h-2v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5h-2v-6a2 2 0 012-2h.974zM20 7a2 2 0 11-.001-3.999A2 2 0 0120 7zM2.974 8H6a2 2 0 012 2v6H6v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v-6a2 2 0 012-2h.974zM4 7a2 2 0 11-.001-3.999A2 2 0 014 7z"
                      />
                      <path
                        className={`fill-current text-gray-400 ${
                          pathname.includes('team') && 'text-indigo-300'
                        }`}
                        d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z"
                      />
                    </svg>
                    <span
                      className={`text-sm font-medium ml-3 lg:opacity-0 lg:${
                        !sidebarExpanded ? `sidebar-expanded:` : ``
                      }opacity-100 2xl:opacity-100 duration-200`}
                    >
                      {translate('Architects')}
                    </span>
                  </div>
                </NavLink>
              </li>
              {/* Providers */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname === '/' && 'bg-gray-900'
                }`}
              >
                <NavLink
                  exact
                  to="/admin/providers"
                  className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                    pathname === '/' && 'hover:text-gray-200'
                  }`}
                >
                  <div className="flex items-center">
                    <svg className="flex-shrink-0 h-6 w-6" viewBox="0 0 24 24">
                      <path
                        className={`fill-current text-gray-600 ${
                          pathname.includes('tasks') && 'text-indigo-500'
                        }`}
                        d="M8 1v2H3v19h18V3h-5V1h7v23H1V1z"
                      />
                      <path
                        className={`fill-current text-gray-600 ${
                          pathname.includes('tasks') && 'text-indigo-500'
                        }`}
                        d="M1 1h22v23H1z"
                      />
                      <path
                        className={`fill-current text-gray-400 ${
                          pathname.includes('tasks') && 'text-indigo-300'
                        }`}
                        d="M15 10.586L16.414 12 11 17.414 7.586 14 9 12.586l2 2zM5 0h14v4H5z"
                      />
                    </svg>
                    <span
                      className={`text-sm font-medium ml-3 lg:opacity-0 lg:${
                        !sidebarExpanded ? `sidebar-expanded:` : ``
                      }opacity-100 2xl:opacity-100 duration-200`}
                    >
                      {translate('Providers')}
                    </span>
                  </div>
                </NavLink>
              </li>

              {/* Products */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname === '/' && 'bg-gray-900'
                }`}
              >
                <NavLink
                  exact
                  to="/admin/products"
                  className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                    pathname === '/' && 'hover:text-gray-200'
                  }`}
                >
                  <div className="flex items-center">
                    <InventoryIcon className="text-gray-500" />
                    <span
                      className={`text-sm font-medium ml-3 lg:opacity-0 lg:${
                        !sidebarExpanded ? `sidebar-expanded:` : ``
                      }opacity-100 2xl:opacity-100 duration-200`}
                    >
                      {translate('Products')}
                    </span>
                  </div>
                </NavLink>
              </li>

              {/* File Manager */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname === '/' && 'bg-gray-900'
                }`}
              >
                <NavLink
                  exact
                  to="/admin/folders"
                  className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                    pathname === '/' && 'hover:text-gray-200'
                  }`}
                >
                  <div className="flex items-center">
                    <CloudIcon className="text-gray-500" />
                    <span
                      className={`text-sm font-medium ml-3 lg:opacity-0 lg:${
                        !sidebarExpanded ? `sidebar-expanded:` : ``
                      }opacity-100 2xl:opacity-100 duration-200`}
                    >
                      {translate('File Manager')}
                    </span>
                  </div>
                </NavLink>
              </li>

              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname === '/' && 'bg-gray-900'
                }`}
              >
                <NavLink
                  exact
                  to="/admin/libraries"
                  className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                    pathname === '/' && 'hover:text-gray-200'
                  }`}
                >
                  <div className="flex items-center">
                    <svg className="flex-shrink-0 h-6 w-6" viewBox="0 0 24 24">
                      <path
                        className={`fill-current text-gray-600 ${
                          pathname.includes('tasks') && 'text-indigo-500'
                        }`}
                        d="M8 1v2H3v19h18V3h-5V1h7v23H1V1z"
                      />
                      <path
                        className={`fill-current text-gray-600 ${
                          pathname.includes('tasks') && 'text-indigo-500'
                        }`}
                        d="M1 1h22v23H1z"
                      />
                      <path
                        className={`fill-current text-gray-400 ${
                          pathname.includes('tasks') && 'text-indigo-300'
                        }`}
                        d="M15 10.586L16.414 12 11 17.414 7.586 14 9 12.586l2 2zM5 0h14v4H5z"
                      />
                    </svg>
                    <span
                      className={`text-sm font-medium ml-3 lg:opacity-0 lg:${
                        !sidebarExpanded ? `sidebar-expanded:` : ``
                      }opacity-100 2xl:opacity-100 duration-200`}
                    >
                      {translate('General Libraries')}
                    </span>
                  </div>
                </NavLink>
              </li>

              {/* Team */}
              <SidebarLinkGroup activecondition={pathname.includes('team')}>
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <a
                        href="#0"
                        className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                          pathname.includes('team') && 'hover:text-gray-200'
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                        }}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <LibraryBooksIcon className="text-gray-500" />
                            <span
                              className={`text-sm font-medium ml-3 lg:opacity-0 lg:${
                                !sidebarExpanded ? `sidebar-expanded:` : ``
                              }opacity-100 2xl:opacity-100 duration-200`}
                            >
                              {translate('Static Pages')}
                            </span>
                          </div>
                          {/* Icon */}
                          <div className="flex flex-shrink-0 ml-2">
                            <svg
                              className={`w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400 ${
                                open && 'transform rotate-180'
                              }`}
                              viewBox="0 0 12 12"
                            >
                              <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <div
                        className={`lg:${
                          !sidebarExpanded ? `sidebar-expanded:` : ``
                        }block 2xl:block`}
                      >
                        <ul className={`pl-9 mt-1 ${!open && 'hidden'}`}>
                          {/* <li className="mb-1 last:mb-0">
                            <NavLink
                              exact
                              to="/admin/privateLibraries"
                              className="block text-gray-400 hover:text-gray-200 transition duration-150 truncate"
                            >
                              <span className="text-sm font-medium lg:opacity-0 lg:${!sidebarExpanded?`sidebar-expanded:`:``}opacity-100 2xl:opacity-100 duration-200">
                                {translate("Private Libraries")}
                              </span>
                            </NavLink>
                          </li> */}
                          <li className="mb-1 last:mb-0">
                            <NavLink
                              exact
                              to="/admin/cgu"
                              className="block text-gray-400 hover:text-gray-200 transition duration-150 truncate"
                            >
                              <span
                                className={`text-sm font-medium lg:opacity-0 lg:${
                                  !sidebarExpanded ? `sidebar-expanded:` : ``
                                }opacity-100 2xl:opacity-100 duration-200`}
                              >
                                {translate('CGU')}
                              </span>
                            </NavLink>
                          </li>
                          <li className="mb-1 last:mb-0">
                            <NavLink
                              exact
                              to="/admin/cgv"
                              className="block text-gray-400 hover:text-gray-200 transition duration-150 truncate"
                            >
                              <span
                                className={`text-sm font-medium lg:opacity-0 lg:${
                                  !sidebarExpanded ? `sidebar-expanded:` : ``
                                }opacity-100 2xl:opacity-100 duration-200`}
                              >
                                {translate('CGV')}
                              </span>
                            </NavLink>
                          </li>
                          <li className="mb-1 last:mb-0">
                            <NavLink
                              exact
                              to="/admin/privacy"
                              className="block text-gray-400 hover:text-gray-200 transition duration-150 truncate"
                            >
                              <span
                                className={`text-sm font-medium lg:opacity-0 lg:${
                                  !sidebarExpanded ? `sidebar-expanded:` : ``
                                }opacity-100 2xl:opacity-100 duration-200`}
                              >
                                {translate('Politique de confidentialité')}
                              </span>
                            </NavLink>
                          </li>
                          <li className="mb-1 last:mb-0">
                            <NavLink
                              exact
                              to="/admin/product"
                              className="block text-gray-400 hover:text-gray-200 transition duration-150 truncate"
                            >
                              <span
                                className={`text-sm font-medium lg:opacity-0 lg:${
                                  !sidebarExpanded ? `sidebar-expanded:` : ``
                                }opacity-100 2xl:opacity-100 duration-200`}
                              >
                                {translate('Produits/Tarifs')}
                              </span>
                            </NavLink>
                          </li>
                          <li className="mb-1 last:mb-0">
                            <NavLink
                              exact
                              to="/admin/supports"
                              className="block text-gray-400 hover:text-gray-200 transition duration-150 truncate"
                            >
                              <span
                                className={`text-sm font-medium lg:opacity-0 lg:${
                                  !sidebarExpanded ? `sidebar-expanded:` : ``
                                }opacity-100 2xl:opacity-100 duration-200`}
                              >
                                {translate('Supports pédagogiques')}
                              </span>
                            </NavLink>
                            <NavLink
                              exact
                              to="/admin/MentionLegal"
                              className="block text-gray-400 hover:text-gray-200 transition duration-150 truncate"
                            >
                              <span
                                className={`text-sm font-medium lg:opacity-0 lg:${
                                  !sidebarExpanded ? `sidebar-expanded:` : ``
                                }opacity-100 2xl:opacity-100 duration-200`}
                              >
                                {translate('Mentions légales')}
                              </span>
                            </NavLink>

                            <NavLink
                              exact
                              to="/admin/fabricant"
                              className="block text-gray-400 hover:text-gray-200 transition duration-150 truncate"
                            >
                              <span
                                className={`text-sm font-medium lg:opacity-0 lg:${
                                  !sidebarExpanded ? `sidebar-expanded:` : ``
                                }opacity-100 2xl:opacity-100 duration-200`}
                              >
                                {translate('Fabricants')}
                              </span>
                            </NavLink>


                            
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup>

              <SidebarLinkGroup activecondition={pathname.includes('team')}>
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <a
                        href="#0"
                        className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                          pathname.includes('team') && 'hover:text-gray-200'
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                        }}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <LibraryBooksIcon className="text-gray-500" />
                            <span
                              className={`text-sm font-medium ml-3 lg:opacity-0 lg:${
                                !sidebarExpanded ? `sidebar-expanded:` : ``
                              }opacity-100 2xl:opacity-100 duration-200`}
                            >
                              {translate('Sources')}
                            </span>
                          </div>
                          {/* Icon */}
                          <div className="flex flex-shrink-0 ml-2">
                            <svg
                              className={`w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400 ${
                                open && 'transform rotate-180'
                              }`}
                              viewBox="0 0 12 12"
                            >
                              <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <div
                        className={`lg:${
                          !sidebarExpanded ? `sidebar-expanded:` : ``
                        }block 2xl:block`}
                      >
                        <ul className={`pl-9 mt-1 ${!open && 'hidden'}`}>
                          {/* <li className="mb-1 last:mb-0">
                            <NavLink
                              exact
                              to="/admin/privateLibraries"
                              className="block text-gray-400 hover:text-gray-200 transition duration-150 truncate"
                            >
                              <span className="text-sm font-medium lg:opacity-0 lg:${!sidebarExpanded?`sidebar-expanded:`:``}opacity-100 2xl:opacity-100 duration-200">
                                {translate("Private Libraries")}
                              </span>
                            </NavLink>
                          </li> */}
                          <li className="mb-1 last:mb-0">
                            <NavLink
                              exact
                              to="/admin/families"
                              className="block text-gray-400 hover:text-gray-200 transition duration-150 truncate"
                            >
                              <span
                                className={`text-sm font-medium lg:opacity-0 lg:${
                                  !sidebarExpanded ? `sidebar-expanded:` : ``
                                }opacity-100 2xl:opacity-100 duration-200`}
                              >
                                {translate('Families')}
                              </span>
                            </NavLink>
                          </li>
                          <li className="mb-1 last:mb-0">
                            <NavLink
                              exact
                              to="/admin/units"
                              className="block text-gray-400 hover:text-gray-200 transition duration-150 truncate"
                            >
                              <span
                                className={`text-sm font-medium lg:opacity-0 lg:${
                                  !sidebarExpanded ? `sidebar-expanded:` : ``
                                }opacity-100 2xl:opacity-100 duration-200`}
                              >
                                {translate('Units')}
                              </span>
                            </NavLink>
                          </li>
                          <li className="mb-1 last:mb-0">
                            <NavLink
                              exact
                              to="/admin/vat"
                              className="block text-gray-400 hover:text-gray-200 transition duration-150 truncate"
                            >
                              <span
                                className={`text-sm font-medium lg:opacity-0 lg:${
                                  !sidebarExpanded ? `sidebar-expanded:` : ``
                                }opacity-100 2xl:opacity-100 duration-200`}
                              >
                                {translate('VAT')}
                              </span>
                            </NavLink>
                          </li>
                          <li className="mb-1 last:mb-0">
                            <NavLink
                              exact
                              to="/admin/cctp"
                              className="block text-gray-400 hover:text-gray-200 transition duration-150 truncate"
                            >
                              <span
                                className={`text-sm font-medium lg:opacity-0 lg:${
                                  !sidebarExpanded ? `sidebar-expanded:` : ``
                                }opacity-100 2xl:opacity-100 duration-200`}
                              >
                                {translate('CCTP')}
                              </span>
                            </NavLink>
                          </li>
                          <li className="mb-1 last:mb-0">
                            <NavLink
                              exact
                              to="/admin/chap0"
                              className="block text-gray-400 hover:text-gray-200 transition duration-150 truncate"
                            >
                              <span
                                className={`text-sm font-medium lg:opacity-0 lg:${
                                  !sidebarExpanded ? `sidebar-expanded:` : ``
                                }opacity-100 2xl:opacity-100 duration-200`}
                              >
                                {translate('Chap0')}
                              </span>
                            </NavLink>
                          </li>
                          <li className="mb-1 last:mb-0">
                            <NavLink
                              exact
                              to="/admin/stakeholders"
                              className="block text-gray-400 hover:text-gray-200 transition duration-150 truncate"
                            >
                              <span
                                className={`text-sm font-medium lg:opacity-0 lg:${
                                  !sidebarExpanded ? `sidebar-expanded:` : ``
                                }opacity-100 2xl:opacity-100 duration-200`}
                              >
                                {translate('Stakeholders')}
                              </span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup>
              {/* Messages */}
            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg
                className={`w-6 h-6 fill-current ${
                  !sidebarExpanded ? `sidebar-expanded:` : ``
                }rotate-180`}
                viewBox="0 0 24 24"
              >
                <path
                  className="text-gray-400"
                  d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                />
                <path className="text-gray-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
