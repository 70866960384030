import React, { useEffect } from "react";
import ArchitectsTable from "../../../components/Table";
import Button from "../../../components/Button";
import AddIcon from "@mui/icons-material/Add";
import Modal from "../../../components/Modal";
import DeleteModal from "../../../components/DeleteModal";
import Notifier from "../../../utils/Notifier";
import { useFormik } from "formik";
import { ADMIN_STATE } from "../../../components/formik/initialValues";
import {
  ADMIN_YUP,
  EDIT_ADMIN_YUP,
} from "../../../components/formik/validations";
import AddAdmin from "./addAdmin";
import EditAdmin from "./editAdmin";

import { AdminheadCells } from "../../../utils/tableHeads";
import Axios from "axios";
import { baseUrl } from "../../../utils/BaseUrl";
import { getToken, getUser } from "../../../utils/Auth";
import { useTranslation } from "react-i18next";

export default function Index(props) {
  const { translate } = props;
  const token = getToken();
  const USER = getUser();
  const [modal, setModal] = React.useState(false);
  const [editmodal, seteditModal] = React.useState(false);
  const [deletemodal, setdeleteModal] = React.useState(false);
  const [headCells, setheadCells] = React.useState("");

  const [adminId, setadminId] = React.useState("");
  const [adminDeleteId, setadminDeleteId] = React.useState("");

  const [adminsData, setadminsData] = React.useState([]);
  const [editData, seteditData] = React.useState([]);
  const { t } = useTranslation();
  const handleModal = () => setModal(!modal);
  //   delete architect modal
  const handleDeleteModal = (id) => {
    if (id) {
      setadminDeleteId(id);
    }
    setdeleteModal(!deletemodal);
  };
  //delete architect
  const handleDelete = async () => {
    try {
      let response = await Axios({
        method: "delete",
        url: `${baseUrl}/Admins/${adminDeleteId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      Notifier(response.data.message, "success", translate);
    } catch (error) {
      if (error.response)
        Notifier(error.response.data.message, "error ", translate);
    }

    setdeleteModal(!deletemodal);
  };
  const handleEditModal = async (id) => {
    if (id) {
      setadminId(id);
      try {
        let filteredData = adminsData.find((data) => data._id == id);
        seteditData(filteredData);
      } catch (error) {
        if (error.response)
          Notifier(error.response.data.message, "error ", translate);
      }
    }
    seteditModal(!editmodal);
  };

  const getAdmins = async () => {
    try {
      let response = await Axios({
        method: "get",
        url: `${baseUrl}/Admins/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let data = response.data.admins;
      let newData = data.filter((dat) => dat.email !== USER.email);
      setadminsData(newData);
    } catch (error) {
      if (error.response)
        Notifier(error.response.data.message, "error ", translate);
    }
    //}
  };

  useEffect(() => {
    getAdmins();
  }, [modal, editmodal, deletemodal]);
  useEffect(() => {
    let array = [];
    AdminheadCells.slice(0, AdminheadCells.length - 1).map((cell) =>
      array.push(cell.id)
    );
    setheadCells(array);
  }, []);

  const formik = useFormik({
    initialValues: ADMIN_STATE,
    validationSchema: ADMIN_YUP,
    onSubmit: async (values) => {
      try {
        let response = await Axios({
          method: "post",
          url: `${baseUrl}/Admins/register`,
          data: values,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        Notifier(response.data.message, "success", translate);
        handleModal();
        formik.resetForm();
      } catch (err) {
        Notifier(err.response.data.message, "error", translate);

        console.log("Errror->>>", err);
      }
    },
  });
  const edit_formik = useFormik({
    initialValues: ADMIN_STATE,
    validationSchema: EDIT_ADMIN_YUP,
    onSubmit: async (values) => {
      try {
        let response = await Axios({
          method: "put",
          url: `${baseUrl}/Admins/${adminId}`,
          data: values,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        Notifier(response.data.message, "success", translate);
        handleEditModal();
      } catch (err) {
        Notifier(err.response.data.message, "error", translate);

        console.log("Errror->>>", err);
      }
    },
  });
  return (
    <>
      <Modal
        title="Add Admin"
        open={modal}
        translate={translate}
        onClose={handleModal}
        buttonLabel="Create"
        onSubmit={formik.handleSubmit}
        component={<AddAdmin translate={translate} formik={formik} />}
      />
      <Modal
        title="Edit Admin"
        open={editmodal}
        translate={translate}
        onClose={handleEditModal}
        buttonLabel={t("Update")}
        onSubmit={edit_formik.handleSubmit}
        component={
          <EditAdmin
            editData={editData}
            editmodal={editmodal}
            formik={edit_formik}
            translate={translate}
          />
        }
      />
      <Modal
        // title="Add Architect"
        open={deletemodal}
        onClose={handleDeleteModal}
        width="35%"
        delete={true}
        component={
          <DeleteModal
            handleClose={handleDeleteModal}
            handleDelete={handleDelete}
            translate={translate}
          />
        }
      />
      <div className="w-full  md:mx-auto py-9 ">
        <div className="container mx-auto px-6">
          <div className="flex justify-between pb-3">
            <h1 className="text-2xl font-bold">{translate("Admins")}</h1>
            <Button
              variant="outlined"
              label="Add Admin"
              onClick={handleModal}
              translate={translate}
              startIcon={<AddIcon />}
            />
          </div>
          <ArchitectsTable
            headCells={AdminheadCells}
            translate={translate}
            data={adminsData}
            handleEdit={handleEditModal}
            handleDelete={handleDeleteModal}
            rows={headCells}
          />
        </div>
      </div>
    </>
  );
}
