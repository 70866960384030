import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // fallbackLng: ["fr", "en"],
    // we init with resources
    resources: {
      en: {
        translations: {
          // header
          Dashboard: 'Dashboard',
          Presentation: 'Presentation',
          'Educational Materials': 'Educational Materials',
          Documentation: 'Documentation',
          Manufacturers: 'Manufacturers',
          Login: 'Login',
          Ordered: 'Ordered',

          //  sidebar
          Pages: 'Pages',
          Dashboard: 'Dashboard',
          'Payment successfully.': 'Payment successfully.',
          // register
          'Create an Account!': 'Create an Account!',
          'First Name': 'First Name',
          'Last Name': 'Last Name',
          Name: 'Name',
          Email: 'Email',
          Password: 'Password',
          'Confirm Password': 'Confirm Password',
          'Please choose a password.': 'Please choose a password.',
          Register: 'Register',
          'Already have an account?': 'Already have an account?',
          //  login
          'Forgot Password?': 'Forgot Password?',
          //  ArchitectheadCells
          Authorize: 'Authorize',
          Subscription: 'Subscription',
          subscription: 'subscription',
          'Subscription End Date': 'Subscription End Date',
          Service: 'Service',
          'Date/Time': 'Date/Time',
          Action: 'Action',
          //  AdminheadCells
          Symbol: 'Symbol',
          Ranking: 'Ranking',
          //  VatheadCells
          Value: 'Value',
          Type: 'Type',
          Title: 'Title',
          Text: 'Text',
          //  ProviderheadCells
          'Telephone No': 'Phone number',
          'Contact No.': 'Contact No.',
          'Address 1': 'Address 1',
          'Address 2': 'Address 2',
          City: 'City',
          ZipCode: 'ZipCode',
          Url: 'url',
          //  Architects
          Architects: 'Architects',
          'Add Architect': 'Add Architect',
          'Rows per page:': 'Rows per page:',
          //  add Architects
          Establishment: 'Establishment',
          Authorize: 'Authorize',
          Yes: 'Yes',
          No: 'No',
          Close: 'Close',
          Create: 'Create',
          // set subscription
          'Set Subscription': 'Set Subscription',
          'Select Subscription': 'Select Subscription',
          Save: 'Save',
          'Select An Option': 'Select An Option',
          '3 months subscription': '3 months subscription',
          '6 months subscription': '6 months subscriptiondfre',
          Yearly: 'Yearly',
          //   update Architechs
          'Edit Architect': 'Edit Architect',
          Update: 'Update',
          Updating: 'Updating',
          'Are you sure?': 'Are you sure?',
          'Do you really want to delete ? This process cannot be undone':
            'Do you really want to delete ? This process cannot be undone',
          Delete: 'Delete',
          Cancel: 'Cancel',
          //   Admin
          Admins: 'Admins',
          'Add Admin': 'Add Admin',
          'Edit Admin': 'Edit Admin',
          //  Providers
          Providers: 'Providers',
          'Add Provider': 'Add Provider',
          'Edit Provider': 'Edit Provider',
          //  Libraries
          Libraries: 'Libraries',
          //   Libraries => General Libraries
          'General Libraries': 'General Libraries',
          'Add Library': 'Add Library',
          Edit: 'Edit',
          'Add Category': 'Add Category',
          'Add Work': 'Add Work',
          'Add Work_Detail': 'Add Work Detail',
          TestWork: 'TestWork',
          'Test Category': 'Test Category',
          Unit: 'Unit',
          Quantity: 'Quantity',
          'Unit Price': 'Unit Price',
          Required: 'Required',
          'Edit Category': 'Edit Category',
          'Edit Library': 'Edit Library',
          'Edit Work': 'Edit Work',
          development: 'development',
          Category1: 'Category1',
          Category2: 'Category2',
          Software: 'Software',
          Symbol: 'Symbol',
          Ranking: 'Ranking',
          //  Unit
          Units: 'Units',
          'Add Unit': 'Add Unit',
          'Edit Unit': 'Edit Unit',
          //  VAT
          VAT: 'VAT',
          'Add VAT': 'Add VAT',
          'Edit VAT': 'Edit VAT',
          //   ChapO
          ChapO: 'ChapO',
          'Edit ChapO': 'Edit ChapO',
          'Edit Chap0': 'Chap0 Part2',
          'Add Chap0': 'Add Chap0',
          //  CCTP
          CCTP: 'CCTP',
          'Edit CCTP Title': 'Edit CCTP Title',
          'Add CCTP': 'Add CCTP',

          /////    Backend Notification
          //Admin
          'Admin already exists': 'Admin already exists',
          'Admin created successfully': 'Admin created successfully',
          'User not found!': 'User not found!',
          'Invalid Password': 'Invalid Password',
          'User login successfully': 'User login successfully',
          'Admin does not exist': 'Admin does not exist',
          'Email Sent successfully': 'Email Sent successfully',
          'Invalid Token!': 'Invalid Token!',
          'Token Expired!': 'Token Expired!',
          'Password Updated successfully': 'Password Updated successfully',
          'Admin updated successfully': 'Admin updated successfully',
          'Internal server error': 'Internal server error',
          'Admin deleted successfully': 'Admin deleted successfully',
          //// Architects
          'Architect already exists': 'Architect already exists',
          'Architect created successfully': 'Architect created successfully',
          'BookLibrary already exists': 'BookLibrary already exists',
          'BookLibrary created successfully': 'BookLibrary created successfully',
          'Architect does not exist': 'Architect does not exist',
          'Architect updated successfully': 'Architect updated successfully',
          'Architect not found': 'Architect not found',
          'Architect Deleted Successfully': 'Architect Deleted Successfully',
          'BookLibraries  not found': 'BookLibraries  not found',
          'Subscription Removed!': 'Subscription Removed!',
          'Subscription Updated!': 'Subscription Updated!',
          //  BookLibraries
          'BookLibrary updated successfully': 'BookLibrary updated successfully',
          'BookLibrary deleted successfully': 'BookLibrary deleted successfully',
          'BookLibray id not found': 'BookLibray id not found',
          'Family id not found': 'Family id not found',
          'Family added Successfully': 'Family added Successfully',
          'Category name exists': 'Category name exists',
          'Category added Successfully': 'Category added Successfully',
          'Category id not found': 'Category id not found',
          'Category updated Successfully': 'Category updated Successfully',
          'Category  not found': 'Category  not found',
          'Category deleted successfully': 'Category deleted successfully',
          'Work name exists': 'Work name exists',
          'Work added Successfully': 'Work added Successfully',
          'Work updated Successfully': 'Work updated Successfully',
          'Work  not found': 'Work  not found',
          'Work deleted successfully': 'Work deleted successfully',
          'workId id not found': 'workId id not found',
          'Work Detail  exists': 'Work Detail  exists',
          'Work Detail added Successfully': 'Work Detail added Successfully',
          'Work id not found': 'Work id not found',
          'Work Detail updated Successfully': 'Work Detail updated Successfully',
          'Work Detail  not found': 'Work Detail  not found',
          'WorkDetail deleted successfully': 'WorkDetail deleted successfully',
          //    CCTP
          'CCTP already exists': 'CCTP already exists',
          'CCTP created successfully': 'CCTP created successfully',
          'CCTP updated successfully': 'CCTP updated successfully',
          'CCTP deleted successfully': 'CCTP deleted successfully',
          'CCTP Deleted!': 'CCTP Deleted!',
          //  Chap0
          'Chap0 already exists': 'Chap0 already exists',
          'Chap0 created successfully': 'Chap0 created successfully',
          'Chap0 updated successfully': 'Chap0 updated successfully',
          'Chap0 deleted successfully': 'Chap0 deleted successfully',
          'Chap0 Deleted!': 'Chap0 supprimé !',
          //  VAT
          'VAT already exists': 'VAT already exists',
          'VAT created successfully': 'VAT created successfully',
          'VAT updated successfully': 'VAT updated successfully',
          'VAT deleted successfully': 'VAT deleted successfully',
          'VAT Deleted!': 'VAT Deleted!',
          //  Unit
          'Unit already exists': 'Unit already exisfts',
          'Unit created successfully': 'Unit created successfully',
          'Unit updated successfully': 'Unit updated successfully',
          'Unit deleted successfully': 'Unit deleted successfully',
          'Unit Deleted!': 'Unit Deleted!',
          // Families
          'Family already exists': 'Family already exists',
          'Family created successfully': 'Family created successfully',
          'Family updated successfully': 'Family updated successfully',
          'Family deleted successfully': 'Family deleted successfully',
          'Family  not found': 'Family  not found',
          //// Provider
          'Provider already exists': 'Provider already exists',
          'Provider created successfully': 'Provider created successfully',
          'Provider updated successfully': 'Provider updated successfully',
          'Provider deleted successfully': 'Provider deleted successfully',
          'Provider  not found': 'Provider  not found',
          //// Subscription
          'Subscription already exists': 'Subscription already exists',
          'Subscription created successfully': 'Subscription created successfully',
          'Subscription updated successfully': 'Subscription updated successfully',
          'Subscription deleted successfully': 'Subscription deleted successfully',
          'Subscription  not found': 'Subscription  not found',
          // contact
          "contact":"contact",
          "Please Enter Name":"Please Enter Name",
          "Please Enter Contact":"Please Enter Contact",
          "Please Enter Email":"Please Enter Email",
          "Please Enter Message":"Please Enter Message",

          // New
          'Welcome to Quadrabase': 'Welcome to Quadrabase',
          'Quadrabase is a business application aimed at automatically producing technical written documents for business consultation files (DCE)':
            'Quadrabase is a business application aimed at automatically producing technical written documents for business consultation files (DCE)',
          'Select Projects': 'Select Projects',
          'Total Projects': 'Total Projects',
          'Total Products': 'Total Products',
          'Address 1': 'Address 1',
          'Address 2': 'Address 2',
          'PO Box': 'PO Box',
          'Postal Code': 'Postal Code',
          City: 'City',
          Phone: 'Phone',
          'Email Address (Client)': 'Email Address (Client)',
          'Stakeholders List': 'List of types of stakeholders',
          'Edit Stakeholder': 'Edit Stakeholder',
          Chap0: 'Chap0 Part2',
          Families: 'Families',
          'Families List': 'Families List',
          'Add Family': 'Add Family',
          'Edit Work Detail': 'Edit Work Detail',
          Up: 'Up',
          Down: 'Down',
          Units: 'Units',
          Stakeholders: 'Stakeholders',
          'Add Work detail': 'Add Work detail',
          Name: 'Name',
          'Select unit': 'Select unit',
          Close: 'Close',
          Create: 'Create',
          'Ongoing Projects': 'Ongoing Projects',
          'Remaining Projects': 'Remaining Projects',
          'Subscription Start Date': 'Subscription Start Date',
          'List Of Works': 'List Of Works',
          'Select Family': 'Select Family',
          'Select Provider': 'Select Provider',
          'Units List': 'Units List',
          'Edit Units': 'Edit Units',
          'VATS List': 'VATS List',
          'CCTP List': 'CCTP List',
          'Add CCTP': 'Add CCTP',
          'Add Stakeholder': 'Add Stakeholder',
          'Edit Family Title': 'Edit Family Title',
          'Client Code': 'Client Code',
          Alerts: 'Alerts',
          Advertising: 'Advertising',
          'Provider Subscription': 'Provider Subscription',
          'Start Date': 'Start Date',
          'End Date': 'End Date',
          'Sub Start Date': 'Start Date',
          'Number of projects': 'Total Projects',
          Top: 'Top',
          Bottom: 'Bottom',
          'Choose file': 'Choose file',
          "Select a manufacturer first":"Select a manufacturer first",
          'no file chosen': 'no file chosen',
          'Stakeholder Type created successfully': 'Stakeholder Type created successfully',
          'Stakeholder type deleted successfully': 'Stakeholder type deleted successfully',
          'Stakeholder type updated successfully': 'Stakeholder type updated successfully',
          'Provider List': 'Provider List',
          'Provider Lists': 'Provider Lists',
          Add: 'Add',
          'Associated Provider': 'Associated Provider',
          'Associated Providers': 'Associated Providers',
          Remove: 'Remove',
          'Must be exactly 5 characters': 'Must be exactly 5 characters',
          Required: 'Required',
          'Invalid email address': 'Invalid email address',
          'Add Chap0': 'Add Chap0',
          'Edit Chap0': 'Edit Chap0',
          'Add Chap0 Level2': 'Add Chap0 Level2',
          'Edit Chap0 Level2': 'Edit Chap0 Level2',
          'Add Chap0 Level3': 'Add Chap0 Level3',
          'Edit Chap0 Level3': 'Edit Chap0 Level3',
          'Add Chap0Level1': 'Add Chap0Level1',
          AddChap0Level2: 'Add Chap0Level2',
          AddChap0Level3: 'Add Chap0Level3',
          'Chap0Level2 created successfully': 'Chap0Level2 created successfully',
          'Chap0Level2 updated successfully': 'Chap0Level2 updated successfully',
          'Chap0Level2 deleted successfully': 'Chap0Level2 deleted successfully',
          'Chap0Level3 created successfully': 'Chap0Level3 created successfully',
          'Chap0Level3 updated successfully': 'Chap0Level3 updated successfully',
          'Chap0Level3 deleted successfully': 'Chap0Level3 deleted successfully',
          'Chap0Level3 deleted successfully': 'Chap0Level3 deleted successfully',
          'Subscription is required': 'Subscription is required',
          'Text is required.': 'Text is required.',
          Subscriptions: 'Subscriptions',
          'Buy Subscription': 'Buy Subscription',
          'Buy Subscriptions': 'Buy Subscriptions',
          'My Subscription': 'My Subscription',
          'No Subscription Assigned': 'No Subscription Assigned',
          'Number of remaining projects': 'Number of remaining projects',
          'Buy Projects': 'Buy Projects',
          'Subscription Valid until': 'Subscription Valid until',
          Renew: 'Renew',
          'My Projects': 'My Projects',
          'New Projects': 'New Projects',
          'New Project': 'New Project',
          Name: 'Name',
          'End Date': 'End Date',
          Credits: 'Credits',
          Projects: 'Projects',
          'Assigned To': 'Assigned To',
          Actions: 'Actions',
          Email: 'Email',
          'Contact Number': 'Contact Number',
          Service: 'Service',
          Entity: 'Entity',
          City: 'City',
          'Address 1': 'Address 1',
          'Address 2': 'Address 2',
          Zipcode: 'Zipcode',
          Title: 'Title',
          Prescriber: 'Prescriber',
          VAT: 'VAT',
          'Type Of Contract': 'Type Of Contract',
          'Type Of Project': 'Project Structure',
          Date: 'Date',
          'Assign Subscription': 'Assign Subscription',
          'Renew Subscription': 'Renew Subscription',
          'Add Project': 'Add Project',
          Simple: 'Simple',
          Trades: 'Trades',
          Trade: 'trade',
          'Trades And Tranches': 'Trades And Tranches',
          'Purchase Order': 'Purchase Order',
          PurchaseOrder: 'Purchase Order',
          English: 'English',
          French: 'French',
          Stakeholders: 'Stakeholders',
          'Project Libraries': 'Project Libraries',
          'My Profile': 'My Profile',
          Logout: 'Logout',
          'Add Stakeholder': 'Add Stakeholder',
          'Add Chap0Part2': 'Add Chap0Part2',
          'Edit Stakeholder': 'Edit Stakeholder',
          'Edit Chap0Part2': 'Edit Chap0Part2',
          Type: 'Type',
          Update: 'Update',
          Create: 'Create ',
          'Are You Sure?': 'Are You Sure?',
          'Do you really want to delete? This process cannot be undone?':
            'Do you really want to delete? This process cannot be undone?',
          Cancel: 'Cancel',
          Delete: 'Delete',
          Text: 'Text',
          'Add Libraries': 'Add Libraries',
          'Manage Families': 'Manage Families',
          Edit: 'Edit',
          Up: 'Up',
          Down: 'Down',
          'Add Category': 'Add Category',
          'Add Work': 'Add Work',
          'Add Work Detail': 'Add Work Detail',
          'Manage Families': 'Manage Families',
          'Add Library': 'Add Library',
          'Edit Library': 'Edit Library',
          'Edit Category': 'Edit Category',
          'Edit Work': 'Edit Work',
          'Edit Work Detail': 'Edit Work Detail',
          'Add Family': 'Add Family',
          'Select Family': 'Select Family',
          Unit: 'Unit',
          'Select Provider': 'Select Provider',
          'Add Provider': 'Add Provider',
          'Select An Option': 'Select An Option',
          'Member Since': 'Member Since',
          Firstname: 'Firstname',
          Lastname: 'Lastname',
          'Old Password': 'Old Password',
          'New Password': 'New Password',
          Establishment: 'Establishment',
          'Please Wait, Loading Project Data!': 'Please Wait, Loading Project Data!',
          'Please Wait, Generating CCTP report!': 'Please Wait, Generating CCTP report!',
          'Success!': 'Success!',
          'Architect Not Found!': 'Architect Not Found!',
          'Subscription Unassigned!': 'Subscription Unassigned!',
          'Subscription Assigned!': 'Subscription Assigned!',
          'Project already exists': 'Project already exists',
          'Project created successfully': 'Project created successfully',
          'stakeholder already exists': 'stakeholder already exists',
          'Stakeholder created successfully': 'Stakeholder created successfully',
          'Stakeholder updated successfully': 'Stakeholder updated successfully',
          'Stakeholder in use of a project': 'Stakeholder in use of a project',
          'Stakeholder deleted successfully': 'Stakeholder deleted successfully',
          'ArchitectsChap0 already exists': 'ArchitectsChap0 already exists',
          'ArchitectsChap0 created successfully': 'ArchitectsChap0 created successfully',
          'ArchitectsChap0 already exists': 'ArchitectsChap0 already exists',
          'ArchitectsChap0 updated successfully': 'ArchitectsChap0 updated successfully',
          'ArchitectsChap0 in use of a project': 'ArchitectsChap0 in use of a project',
          'ArchitectsChap0 deleted successfully': 'ArchitectsChap0 deleted successfully',
          'Family updated successfully': 'Family updated successfully',
          'Family deleted successfully': 'Family deleted successfully',
          'Family created successfully': 'Family created successfully',
          'Family already exists': 'Family already exists',
          'BookLibrary already exists': 'BookLibrary already exists',
          'BookLibrary created successfully': 'BookLibrary created successfully',
          'BookLibraries  not found': 'BookLibraries  not found',
          'BookLibrary deleted successfully': 'BookLibrary deleted successfully',
          'BookLibrary updated successfully': 'BookLibrary updated successfully',
          'Category  not found': 'Category  not found',
          'Category name exists': 'Category name exists',
          'Category added Successfully': 'Category added Successfully',
          'Category updated Successfully': 'Category updated Successfully',
          'Category deleted successfully': 'Category deleted successfully',
          'Work name exists': 'Work name exists',
          'Work added Successfully': 'Work added Successfully',
          'Work updated Successfully': 'Work updated Successfully',
          'Work deleted successfully': 'Work deleted successfully',
          'Work Detail  exists': 'Work Detail  exists',
          'Work Detail added Successfully': 'Work Detail added Successfully',
          'Work Detail updated Successfully': 'Work Detail updated Successfully',
          'Work Detail  not found': 'Work Detail  not found',
          'WorkDetail deleted successfully': 'WorkDetail deleted successfully',
          "New Password Can't be Empty!": "New Password Can't be Empty!",
          'Invalid Old Password': 'Invalid Old Password',
          'Updated successfully': 'Updated successfully',
          Properties: 'Properties',
          'To Leave': 'To Leave',
          'General Libraries': 'General Libraries',
          'Project Libraries': 'Project Libraries',
          'Total HT': 'Total HT',
          'Total VAT': 'Total VAT',
          'Total  Amount  including  VAT': 'Total  Amount  including  VAT',
          Designation: 'Designation',
          Unit: 'Unit',
          Qty: 'Qty',
          'Project References': 'Project References',
          'Market Type / Structure': 'Market Type / Structure',
          Reference: 'Reference',
          Description: 'Description',
          'General Chap. 0 part 2': 'General Chap. 0 part 2',
          'Architect Chap. 0 part 2': 'Architect Chap. 0 part 2',
          'Select Stakeholder': 'Select Stakeholder',
          'Seleted General Chap. 0 part 2 already exists in Architect Chap. 0 part 2':
            'Seleted General Chap. 0 part 2 already exists in Architect Chap. 0 part 2',
          'Project updated successfully': 'Project updated successfully',
          'Edit Chap. 0 part 2 - CLAUSES MUNICIPALITIES':
            'Edit Chap. 0 part 2 - CLAUSES MUNICIPALITIES',
          'Add a Work': 'Add a Work',
          'Select Work Details': 'Select Work Details',
          'Project Properties': 'Project Properties',
          'Edit a Work': 'Edit a Work',
          'Edit a Work Detail': 'Edit a Work Detail',
          'Manage Tranches': 'Manage Tranches',
          Submit: 'Submit',
          'Work added to project': 'Work added to project',
          Close: 'Close',
          'Add Tranch': 'Add Tranch',
          'Tranch already exists': 'Tranch already exists',
          'Tranch created successfully': 'Tranch created successfully',
          'Tranch updated successfully': 'Tranch updated successfully',
          'Tranch deleted successfully': 'Tranch deleted successfully',
          'Duplicate work Detail created': 'Duplicate work Detail created',
          'Duplicate work created': 'Duplicate work created',
          Empty: 'Empty',
          'Generate Estimate': 'Generate Estimate',
          'Generate The DQE': 'Generate The DQE',
          'Generate BPU': 'Generate BPU',
          'Generate DPGF': 'Generate DPGF',
          'Generate The CCTP': 'Generate The CCTP',
          'Technical Doc': 'Technical Doc',
          'To Modify': 'To Modify',
          'To Delete': 'To Delete',
          Duplicate: 'Duplicate',
          Top: 'Top',
          Up: 'Up',
          Down: 'Down',
          Bottom: 'Bottom',
          'Select Tranch': 'Select Tranch',
          'Add New': 'Add New',
          'Type of stakeholder': 'Type of stakeholder',
          'Name of stakeholder': 'Name of stakeholder',
          'Tranch is required': 'Tranch is required',
          'Select at least 1 work detail': 'Select at least 1 work detail',
          'Architect Stakeholders': 'Architect Stakeholders',
          'Buy Credits': 'Buy Credits',
          'Manage Trades': 'Manage Trades',
          'Add Trade': 'Add Trade',
          'Trade created successfully': 'Trade created successfully',
          'Trade updated successfully': 'Trade updated successfully',
          'Trade deleted successfully': 'Trade deleted successfully',
          'Project Name': 'Project Name',
          'Chapter selection': 'Chapter selection',
          'The last remaining Batch cannot be deleted.':
            'The last remaining Batch cannot be deleted',
          'The last remaining Slice cannot be deleted.':
            'The last remaining Slice cannot be deleted',
          'Delete tranches first': 'Delete tranches first',
          'Private Libraries': 'Private Libraries',
          Architect: 'Architect',
          Home: 'Home',
          Management: 'Management',
          Profile: 'Profile',
          'Remaning Projects': 'Remaning Projects',
          'Valid Until': 'Valid Until',
          'No technical document in this project': 'No technical document in this project',
          'To use the Quadrabase application': 'To use the Quadrabase application',
          'you must subscribe': 'you must subscribe',
          'Buy a project pack': 'Buy a project pack',
          'Projets restants': 'Projets restants',
          'Projects in progress': 'Projects in progress',
          'Number of Projects': 'Number of Projects',
          'Please select a chap. 0 part 2 from properties':
            'Please select a chap. 0 part 2 from properties',
          'Project Date': 'Project Date',
          'No text': 'No text',
          'Update Trade': 'Update Trade',
          'Update Tranch': 'Update Tranch',
          'Select a type of stakeholder': 'Select a type of stakeholder',
          'Number of Administrators': 'Number of Administrators',
          'Administrators ': 'Administrators',
          'Number of Architects': 'Number of Architects',
          architects: 'Architects',
          'Number of Providers': 'Number of Providers',

          'Number of Vists': 'Number of Vists',

          'Project in Progress': 'Project in Progress',
          Week: 'Week',
          Month: 'Month',
          providers: 'Providers',
          'Name of the project': 'Name of the project',
          Owner: 'Owner',
          'Date Of Creation': 'Date Of Creation',
          'Date Of Last Modification': 'Date Of Last Modification',
          Status: 'Status',
          'Type Of Stakeholder': 'Type Of Stakeholder',
          'Subscribe to Quadrabase': 'Subscribe to Quadrabase',
          'Add Subscriptions': 'Add Subscriptions',
          Duration: 'Duration',
          'Duration Price': 'Duration Price',
          'Number of Projects': 'Number of Projects',
          'Pack Price': 'Pack Price',
          'Family already in use': 'Family already in use',
          'This family does not have a Chap. 0 part 2':
            'This family does not have a Chap. 0 part 2',
          'Email Address': 'Email Address',
          Telephone: 'Telephone',
          Products: 'Products',
          'Add a product': 'Add a Product',
          'Edit Product': 'Edit Product',
          'Add Level': 'Add Level',
          Family: 'Family',
          'Modify the text of Chap0 Part2': 'Modify the text of Chap0 Part2',
          'Update Status': 'Update Status',
          'Prescriber Libraries': 'Prescriber Libraries',
          'Level created successfully': 'Level created successfully',
          'Deleted successfully': 'Deleted successfully',
          TradesAndTranches: 'TradesAndTranches',
          Simple: 'Simple',
          Rate: 'Rate',
          Rates: 'Rates',
          project: 'project',
          Prescribers: 'Prescribers',
          'File Manager': 'File Manager',
          'Add a folder': 'Add a folder',
          Rename: 'Rename',
          'Add a file': 'Add a file',
          'Folder already exists': 'Folder already exists',
          'Folder created successfully': 'Folder created successfully',
          "Parent folder doesn't exist": "Parent folder doesn't exist",
          'Subfolder already exists': 'Subfolder already exists',
          'Subfolder created successfully': 'Subfolder created successfully',
          'Folder updated successfully': 'Folder updated successfully',
          'File updated successfully': 'File updated successfully',
          'File deleted successfully': 'File deleted successfully',
          'File uploaded successfully': 'File uploaded successfully',
          'No file to upload': 'No file to upload',
          'Folders deleted successfully': 'Folders deleted successfully',
          Provider: 'Provider',
          'No works found for this provider.': 'No works found for this provider.',
          'Rename folder': 'Rename folder',
          'Modify status': 'Modify status',
          'Type of Customer': 'Type of Customer',
          'Price excl. VAT': 'Price excl. VAT',
          'Updated successfully': 'Updated successfully',
          'Static Pages': 'Static Pages',
          Warning: 'Warning',
          Continue: 'Continue',
          'Email is required!': 'Email is required!',
          'Created successfully': 'Created successfully',
          'Add title': 'Add title',
          'Edit text': 'Edit text',
          'Edit Profile': 'Edit Profile'
        }
      },
      fr: {
        translations: {
          'Edit Profile': 'Editer le profil',
          'Created successfully': 'Créé avec succès',
          'Email is required!': "L'e-mail est requis !",
          'Static Pages': 'Pages statiques',
          'Updated successfully': 'Mis à jour avec succés',
          'Number of Administrators': 'Nombre d’administrateurs',
          'Administrators ': 'Administrateurs',
          'Number of Architects': ' Nombre de Prescripteurs ',
          Architects: ' Prescripteurs ',

          'Number of Providers': 'Nombre de Fabricants',

          'Number of Vists': 'Nombre de visites',
          'Project in Progress': 'Projets en cours',
          providers: 'Fabricants',
          Week: 'Semaine',
          Month: 'Mois',
          // header
          Dashboard: 'Tableau de bord',
          Presentation: 'Présentation',
          'Educational Materials': 'Matériel pédagogique',
          Documentation: 'Documentation',
          Manufacturers: 'Fabricants',
          Login: 'Se connecter',
          // register
          'Payment successfully.': 'Paiement avec succès.',
          'Create an Account!': 'Créer un compte',
          'First Name': 'Prénom',
          'Last Name': 'Nom',
          Email: 'Adresse Courriel',
          Password: 'Mot de passe',
          'Confirm Password': 'Confirmer le mot de passe',
          'Please choose a password.': 'Veuillez choisir un mot de passe',
          Register: "S'inscrire",
          'Already have an account?': 'Vous avez déjà un compte ?',
          // login
          'Forgot Password?': 'Mot de passe oublié ?',
          // ArchitectheadCells
          Authorize: 'Autoriser',
          Subscription: 'Abonnement',
          'Subscription End Date': "Date fin d'abonnement",
          Service: 'Service',
          'Date/Time': 'Date et heure',
          Action: 'Action',
          // AdminheadCells
          Admins: 'Administrateurs',
          Name: 'Nom',
          Symbol: 'Symbole',
          Ranking: 'Classement',
          // VatheadCells
          Value: 'Valeur',
          Type: 'Taper',
          Title: 'Titre',
          Text: 'Texte',
          // ProviderheadCells
          'Telephone No': 'Téléphone',
          'Contact No': 'Contact',
          'Address 1': 'Adresse 1',
          'Address 2': 'Adresse 2',
          City: 'Ville',
          ZipCode: 'Code postal',
          Url: 'URL',
          // Architects
          Architects: 'Prescripteurs',
          'Add Architect': 'Ajouter un prescripteur',
          'Rows per page:': 'Lignes par page',
          // add Architects
          Establishment: 'Établissement',
          Authorize: 'Autoriser',
          Yes: 'Oui',
          No: 'Non',
          Close: 'Fermer',
          Create: 'Créer',
          // set subscription
          'Set Subscription': "Définir l'abonnement",
          'Select Subscription': "Sélectionner l'abonnemen",
          Save: 'Enregistrer',
          'Select An Option': 'Sélectionner une option',
          '3 months subscription': "3 mois d'abonnemen",
          '6 months subscription': "6 mois d'abonnemen",
          Yearly: 'Annuel',
          // update Architechs
          'Edit Architect': 'Modifier Prescripteur',
          Update: 'Mettre à jour',
          'Are you sure?': 'Êtes-vous sûr ?',
          'Do you really want to delete ? This process cannot be undone':
            'Voulez-vous vraiment supprimer ? Ce processus ne peut être annulé',
          Delete: 'Supprimer',
          Cancel: 'Annuler',
          // Admin
          'Edit Admin': 'Modifier l’Admin',
          'Add Admin': 'Ajouter un administrateur',
          // Providers
          'Add Provider': 'Ajouter un fabricant',
          Providers: 'Fabricants',
          BPU: 'BPU',
          'Edit Provider': 'Modifier le Fabricant',
          // Libraries
          Libraries: 'Bibliothèques',
          // Libraries => General Libraries
          'General Libraries': 'Bibliothèques générales',
          'Add Library': 'Ajouter une Bibliothèque',
          Edit: 'Modifier',
          Delete: 'Supprimer',
          'Add Category': 'Ajouter une catégorie',
          'Add Work': 'Ajouter un ouvrage',
          'Add Work_Detail': "Ajouter un détail d'ouvrage",
          TestWork: 'TestWork',
          'Test Category': 'Catégorie de test',
          Unit: 'Unité',
          Quantity: 'Quantité',
          'Unit Price': 'Prix unitaire',
          Required: 'Requis',
          'Edit Category': 'Modifier la Catégorie', // xxyq
          'Edit Library': 'Modifier la Bibliothèque',
          'Edit Work': 'Modifier un ouvrage',
          development: 'Développement',
          Category1: 'Catégorie1',
          Category2: 'Catégorie2',
          Software: 'Logiciel',
          // Unit
          Unitst: 'Unités',
          'Add Unit': 'Ajouter une Unité',
          'Edit Unit': 'Modifier une Unité',
          // VAT
          VAT: 'T.V.A.',
          'Add VAT': 'Ajouter TVA',
          'Edit VAT': 'Modifier',
          // ChapO
          ChapO: 'ChapO',
          // "Edit ChapO": "Modifier ChapO",
          'Edit Chap0': 'Chap0 Part2',
          'Add Chap0': 'Ajouter un Niveau 1',
          // CCTP
          CCTP: 'CCTP',
          'Edit CCTP Title': 'Modifier',
          'Add CCTP': 'Ajouter CCTP',

          /////    Backend Notification
          //Admin
          'Admin already exists': "L'administrateur existe déjà",
          'Admin created successfully': 'Administrateur créé avec succès',
          'User not found!': 'Utilisateur non trouvé!',
          'Invalid Password': 'Mot de passe incorrect',
          'User login successfully': 'Connexion utilisateur réussie',
          'Admin does not exist': "L'administrateur n'existe pas",
          'Email Sent successfully': 'E-mail envoyé avec succès',
          'Invalid Token!': 'Jeton invalide!',
          'Token Expired!': 'Jeton expiré !',
          'Password Updated successfully': 'Mot de passe mis à jour avec succès',
          'Admin updated successfully': "L'administrateur a été mis à jour avec succès",
          'Internal server error': 'Erreur interne du serveur',
          'Admin deleted successfully': 'Administrateur supprimé avec succès',
          //// Architects
          'Architect already exists': 'Le Prescripteur existe déjà',
          'Architect created successfully': 'Le Prescripteur créé avec succès',
          'BookLibrary already exists': 'Bibliothèque existe déjà',
          'BookLibrary created successfully': 'Bibliothèque créé avec succès',
          'Architect does not exist': "Le Prescripteur n'existe pas",
          'Architect updated successfully': 'Le Prescripteur mis à jour avec succès',
          'Architect not found': 'Le Prescripteur introuvable',
          'Architect Deleted Successfully': 'Le prescripteur a été supprimé avec succès.',
          'BookLibraries  not found': 'Bibliothèques non trouvées',
          'Subscription Removed!': 'Abonnement supprimé !',
          'Subscription Updated!': 'Abonnement mis à jour !',
          //  BookLibraries
          'BookLibrary updated successfully': 'Bibliothèque mis à jour avec succès',
          'BookLibrary deleted successfully': 'Bibliothèque supprimé avec succès',
          'BookLibray id not found': 'Identifiant BookLibray introuvable',
          'Family id not found': 'Identifiant de famille introuvable',
          'Family added Successfully': 'Famille ajoutée avec succès',
          'Category name exists': 'Le nom de la catégorie existe',
          'Category added Successfully': 'Catégorie ajoutée avec succès',
          'Category id not found': 'Identifiant de catégorie introuvable',
          'Category updated Successfully': 'Catégorie mise à jour avec succès',
          'Category  not found': 'Catégorie introuvable',
          'Category deleted successfully': 'Catégorie supprimée avec succès',
          'Work name exists': 'Le nom de travail existe',
          'Work added Successfully': 'Travail ajouté avec succès',
          'Work updated Successfully': 'Ouvrage mis à jour avec succès',
          'Work  not found': 'uvre introuvable',
          'Work deleted successfully': 'Ouvrage supprimé avec succès',
          'workId id not found': 'workId id introuvable',
          'Work Detail  exists': 'Le détail du travail existe',
          'Work Detail added Successfully': 'Détail du travail ajouté avec succès',
          'Work id not found': 'Identifiant de travail introuvable',
          'Work Detail updated Successfully': "Détail d'ouvrage mis à jour avec succès",
          'Work Detail  not found': 'Détail du travail introuvable',
          'WorkDetail deleted successfully': "Détail d'ouvrage supprimé avec succès",
          //    CCTP
          'CCTP already exists': 'Le CCTP existe déjà',
          'CCTP created successfully': 'CCTP créé avec succès',
          'CCTP updated successfully': 'CCTP mis à jour avec succès',
          'CCTP deleted successfully': 'Titre CCTP supprimé avec succès',
          'CCTP Deleted!': 'Titre CCTP supprimé avec succès',
          //  Chap0
          'Chap0 already exists': 'Chap0 existe déjà',
          'Chap0 created successfully': 'Niveau ajouté avec succès',
          'Chap0 updated successfully': 'Chap0 mis à jour avec succès',
          'Chap0 deleted successfully': 'Chap0 Supprimé avec succès',
          'Chap0 Deleted!': 'Chap0 Deleted!',
          //  VAT
          'VAT already exists': 'TVA existe déjà',
          'VAT created successfully': 'TVA créé avec succès',
          'VAT updated successfully': 'TVA mis à jour avec succès',
          'VAT deleted successfully': 'Valeur TVA supprimée avec succès',
          'VAT Deleted!': 'Valeur TVA supprimée avec succès',
          //  Unit
          'Unit already exists': 'Unité existe déjà',
          'Unit created successfully': 'Unité créé avec succès',
          'Unit updated successfully': 'Unité mis à jour avec succès',
          'Unit deleted successfully': 'Unité supprimée avec succès',
          'Unit Deleted!': 'Unité supprimée avec succès',
          // Families
          'Family already exists': 'Famille existe déjà',
          'Family created successfully': 'Ajout réussie',
          'Family updated successfully': 'Famille mis à jour avec succès',
          'Family deleted successfully': 'Domaine supprimé avec succès',
          'Family  not found': 'Famille introuvable',
          //// Provider
          'Provider already exists': 'Le Fabricant existe déjà',
          'Provider created successfully': 'Le Fabricant créé avec succès',
          'Provider updated successfully': 'Le Fabricant mis à jour avec succès',
          'Provider deleted successfully': 'Le Fabricant Supprimé avec succès',
          'Provider  not found': 'Le Fabricant introuvable',
          //// Subscription
          'Subscription already exists': 'Abonnement existe déjà',
          'Subscription created successfully': 'SubscrAbonnementiption créé avec succès',
          'Subscription updated successfully': 'Abonnement mis à jour avec succès',
          'Subscription deleted successfully': 'Abonnement Supprimé avec succès',
          'Subscription  not found': 'Abonnement introuvable',
          // contact
          "contact":"contact",
          "Please Enter Name":"Veuillez entrer le nom",
          "Please Enter Contact":"Veuillez entrer le contact",
          "Please Enter Email":"Veuillez saisir un e-mail",
          "Please Enter Message":"Veuillez entrer un message",

          // New
          'Welcome to Quadrabase': 'Bienvenue sur Quadrabase',
          'Quadrabase is a business application aimed at automatically producing technical written documents for business consultation files (DCE)':
            'Quadrabase est une application métier visant à produire automatiquement les pièces écrites techniques des dossiers de consultation des entreprises (DCE) dans le cadre de marchés de travaux',
          'Select Projects': 'Sélection le Produit',
          'Total Projects': 'Projets Nombre',
          'Total Products': 'Total des projets',
          'Address 1': 'Adresse 1',
          'Address 2': 'Adresse 2',
          'PO Box': 'Boite Postale',
          'Postal Code': 'Code Postal',
          City: 'Ville',
          Phone: 'Téléphone',
          'Email Address (Client)': 'Adresse courriel (Client)',
          'Stakeholders List': 'Liste des types d’intervenants',
          'Edit Stakeholder': 'Modifier le Type d’intervenant',
          Chap0: 'Chap0 Part2',
          Families: 'Domaines',
          'Families List': 'Liste des Domaines',
          'Add Family': 'Ajouter un Domaine',
          'Add Work_Detail': 'Ajouter un Détail d’ouvrage',
          'Edit Work Detail': "Modifier les détails d'ouvrage",
          Up: 'Monter',
          Down: 'Descendre',
          Units: 'Unités',
          Stakeholders: 'Intervenants',
          'Add Work detail': "Ajouter un détail d'ouvrage",
          Name: 'Nom',
          'Select Unit': "Sélectionner l'unité",
          Close: 'Fermer',
          Create: 'Créer',
          'Ongoing Projects': 'Projets en cours',
          'Remaining Projects': 'Projets restants',
          'Subscription Start Date': 'Date Début Abonnement',
          'List Of Works': 'Liste des travaux',
          'Select Family': 'Sélectionnez le domaine',
          'Select Provider': 'Fabricants associés',
          'Units List': 'Liste des unités',
          'Edit Units': 'Modifier les unités',
          'VATS List': 'Liste TVA',
          'CCTP List': 'Liste CCTP',
          'Add CCTP': 'Ajouter un Titre CCTP',
          'Add Stakeholder': "Ajouter un type d'Intervenant",
          'Edit Family Title': 'Modifier le titre',
          'Client Code': 'Code Client',
          Alerts: 'Alertes',
          Advertising: 'Référencement',
          'Provider Subscription': 'Abonnements du Fabricant',
          'Start Date': 'Date de démarrage',
          'End Date': 'Date de fin',
          'Sub Start Date': 'Date de début',
          'Number of projects': 'Projets Nombre',
          Top: 'En haut',
          Bottom: 'En bas',
          'Choose file': 'Choisir le fichier',
          "Select a manufacturer first":"Sélectionner d’abord un fabricant",
          'no file chosen': 'aucun fichier choisi',
          'Stakeholder Type created successfully': 'Intervenant ajouté avec succès',
          'Stakeholder type deleted successfully': 'Type de partie prenante supprimé avec succès',
          'Stakeholder type updated successfully': 'Type de partie prenante mis à jour avec succès',
          'Provider List': 'Liste des fabricant',
          'Provider Lists': 'Liste des fabricants',
          Add: 'Ajouter',
          'Associated Provider': 'Fabricant associé',
          'Associated Providers': 'Fabricants associés',
          Remove: 'Retirer',
          'Must be exactly 5 characters': 'Code postal incorrect',
          Required: 'Champ obligatoire',
          'Invalid email address': 'E-mail incorrect',
          'Add Chap0': 'Ajouter un Niveau 1',
          'Edit Chap0': 'Modifier le Niveau 1',
          'Add Chap0 Level2': 'Ajouter un Niveau 2',
          'Edit Chap0 Level2': 'Modifier le Niveau 2',
          'Add Chap0 Level3': 'Ajouter un Niveau 3',
          'Edit Chap0 Level3': 'Modifier le Niveau 3',
          'Add Chap0Level1': 'Ajouter Chap0Niv1',
          AddChap0Level2: 'Ajouter Chap0Niv2',
          AddChap0Level3: 'Ajouter Chap0Niv3',
          'Chap0Level2 created successfully': 'Chap0Level2 créé avec succès',
          'Chap0Level2 updated successfully': 'Chap0Level2 mis à jour avec succès',
          'Chap0Level2 deleted successfully': 'Chap0Level2 supprimé avec succès',
          'Chap0Level3 created successfully': 'Chap0Level3 créé avec succès',
          'Chap0Level3 updated successfully': 'Chap0Level3 mis à jour avec succès',
          'Chap0Level3 deleted successfully': 'Chap0Level3 supprimé avec succès',
          'Subscription is required': "L'abonnement est requis",
          'Text is required.': 'Le texte est requis.',
          Subscriptions: 'Abonnements',
          'Buy Subscription': 'Acheter un abonnement',
          'Buy Subscriptions': 'Acheter un abonnements',
          'My Subscription': 'Mon abonnement',
          'No Subscription Assigned': 'Aucun abonnement attribué',
          'Number of remaining projects': 'Nombre de projets restants',
          'Buy Projects': 'Acheter des crédits',
          'Subscription Valid until': "Abonnement valable jusqu'au",
          Renew: 'Renouveler',
          'My Projects': 'Mes projets',
          'New Projects': 'Nouveau projets',
          'New Project': 'Nouveau projet',
          Name: 'Nom',
          'End Date': 'Date de fin',
          Credits: 'Crédits',
          Projects: 'Projets',
          'Assigned To': 'Assigné à',
          Actions: 'Actions',
          Email: 'E-mail',
          'Contact Number': 'Numéro de contact',
          Service: 'Service',
          Entity: 'Entité',
          City: 'Ville',
          'Address 1': 'Adresse 1',
          'Address 2': 'Adresse 2',
          Zipcode: 'Code postal',
          Title: 'Titre',
          Prescriber: 'Prescriber',
          VAT: 'T.V.A',
          'Type Of Contract': 'Type de marché',
          'Type Of Project': 'Structure du projet',
          Date: 'Date',
          'Assign Subscription': 'Attribuer un abonnement',
          'Renew Subscription': "Renouveler l'abonnement",
          'Add Project': 'Ajouter un projet',
          Simple: 'Simple',
          Trades: 'Lots multiples',
          Trade: 'Lot',
          'Trades And Tranches': 'Lots et tranches',
          'Purchase Order': 'Bon de commande',
          PurchaseOrder: 'Bon de commande',
          English: 'Anglais',
          French: 'Français',
          Stakeholders: 'Intervenants',
          'Project Libraries': 'Bibliothèques de projet',
          'My Profile': 'Mon profil',
          Logout: 'Se déconnecter',
          'Add Stakeholder': 'Ajouter un Intervenant',
          'Add Chap0Part2': 'Ajouter Chap0Part2',
          'Edit Stakeholder': "Modifier  l'ntervenant",
          'Edit Chap0Part2': 'Modifier Chap0Part2',
          Type: 'Entrer',
          Update: 'Mettre à jour',
          Create: 'Créer',
          'Are You Sure?': 'Êtes-vous sûr?',
          'Do you really want to delete? This process cannot be undone?':
            'Voulez-vous vraiment supprimer ? Cette action ne peut pas être annulée ?',
          Cancel: 'Annuler',
          Delete: 'Supprimer',
          Text: 'Texte',
          'Manage Families': 'Gérer les familles',
          Edit: 'Modifier',
          Up: 'Monter',
          Down: 'Descendre',
          'Add Category': 'Ajouter une catégorie',
          'Add Work': 'Ajouter un ouvrage',
          'Add Work Detail': 'Ajouter un détail d’ouvrage',
          'Add Library': 'Ajouter une bibliothèque',
          'Edit Library': 'Modifier la bibliothèque',
          'Edit Category': 'Modifier la catégorie',
          'Edit Work': "Modifier l'ouvrage",
          'Edit Work Detail': 'Modifier le détail d’ouvrage',
          'Add Family': 'Ajouter une famille',
          'Select Family': 'Sélectionnez le domaine',
          Unit: 'Unité',
          'Select Provider': 'Sélectionnez le fabricant',
          'Add Provider': 'Ajouter un fabricant',
          'Select An Option': 'Sélectionner une option',
          'Member Since': 'Membre depuis',
          Firstname: 'Prénom',
          Lastname: 'Nom',
          'Old Password': 'Ancien mot de passe',
          'New Password': 'Nouveau mot de passe',
          Establishment: 'Établissement',
          'Please Wait, Loading Project Data!':
            'Veuillez patienter, chargement des données du projet !',
          'Please Wait, Generating CCTP report!':
            'Veuillez patienter, génération du rapport CCTP !',
          'Success!': 'Succès!',
          'Architect Not Found!': 'Architecte introuvable !',
          'Subscription Unassigned!': 'Abonnement non attribué !',
          'Subscription Assigned!': 'Abonnement attribué !',
          'Project already exists': 'Le projet existe déjà',
          'Project created successfully': 'Projet créé avec succès',
          'stakeholder already exists': "L'intervenant'existe déjà",
          'Stakeholder created successfully': 'Intervenant créé avec succès',
          'Stakeholder updated successfully': 'Intervenant mis à jour avec succès',
          'Stakeholder in use of a project': "Intervenant faisant partie d'un projet",
          'Stakeholder deleted successfully': 'Intervenant supprimé avec succès',
          'ArchitectsChap0 already exists': 'Le Chap0 existe déjà',
          'ArchitectsChap0 created successfully': 'Chap0 créé avec succès',
          'ArchitectsChap0 already exists': 'Chap0 existe déjà',
          'ArchitectsChap0 updated successfully': 'Chap0 mis à jour avec succès',
          'ArchitectsChap0 in use of a project': "Chap0 en cours d'utilisation d'un projet",
          'ArchitectsChap0 deleted successfully': 'Chap0 supprimé avec succès',
          'Family updated successfully': 'Famille mise à jour avec succès',
          'Family deleted successfully': 'Famille supprimée avec succès',
          'Family created successfully': 'Famille créée avec succès',
          'Family already exists': 'La famille existe déjà',
          'BookLibrary already exists': 'La bibliothèque existe déjà',
          'BookLibrary created successfully': 'Bibliothèque créée avec succès',
          'BookLibraries  not found': 'Bibliothèque introuvable',
          'BookLibrary deleted successfully': 'Bibliothèque supprimée avec succès',
          'BookLibrary updated successfully': 'Bibliothèque mise à jour avec succès',
          'Category  not found': 'Catégorie introuvable',
          'Category name exists': 'Le nom de la catégorie existe',
          'Category added Successfully': 'Catégorie ajoutée avec succès',
          'Category updated Successfully': 'Catégorie mise à jour avec succès',
          'Category deleted successfully': 'Catégorie supprimée avec succès',
          'Work name exists': "Le nom de l'ouvrage existe",
          'Work added Successfully': 'Ouvrage ajouté avec succès',
          'Work updated Successfully': 'Ouvrage mis à jour avec succès',
          'Work deleted successfully': 'Ouvrage supprimé avec succès',
          'Work Detail  exists': "Le détail d'Ouvrage existe",
          'Work Detail added Successfully': "Détail d'Ouvrage ajouté avec succès",
          'Work Detail updated Successfully': "Détail d'Ouvrage mis à jour avec succès",
          'Work Detail  not found': "Détail d'Ouvrage introuvable",
          'WorkDetail deleted successfully': "Détail d'Ouvrage supprimé avec succès",
          "New Password Can't be Empty!": 'Le nouveau mot de passe ne peut pas être vide !',
          'Invalid Old Password': 'Ancien mot de passe invalide',
          'Updated successfully': 'Mis à jour avec succés',
          Properties: 'Propriétés',
          'To Leave': 'RETOUR',
          'General Libraries': 'Bibliothèques générales',
          'Project Libraries': 'Bibliothèques de projet',
          'Total HT': 'Total HT',
          'Total VAT': 'TVA total',
          'Total  Amount  including  VAT': 'Montant Total TTC',
          Designation: 'Désignation',
          Unit: 'Unité',
          Qty: 'Qté',
          'Project References': 'Références du projet',
          'Market Type / Structure': 'Type de marché / Structure',
          Reference: 'Référence',
          Description: 'Description',
          'General Chap. 0 part 2': 'Général Chap. 0 partie 2',
          'Architect Chap. 0 part 2': 'Architecte Chap. 0 partie 2',
          'Select Stakeholder': 'Sélectionnez un Intervenant',
          'Seleted General Chap. 0 part 2 already exists in Architect Chap. 0 part 2':
            'Le Chap0Part2 existe déjà.',
          'Project updated successfully': 'Projet mis à jour avec succès',
          'Edit Chap. 0 part 2 - CLAUSES MUNICIPALITIES': 'Modifier Chap0 part2 – CLAUSES COMMUNES',
          'Add a Work': 'Ajouter un Ouvrage',
          'Select Work Details': "Sélectionnez les détails d'Ouvrage'",
          'Project Properties': 'Propriétés du projet',
          'Edit a Work': 'Modifier un Ouvrage',
          'Edit a Work Detail': "Modifier un détail d'Ouvrage",
          'Manage Tranches': 'Gérer les tranches',
          Submit: 'VALIDER',
          'Work added to project': 'Ouvrage ajouté au Projet',
          Close: 'Fermer',
          'Add Tranch': 'Ajouter une tranche',
          'Tranch already exists': 'La tranche existe déjà',
          'Tranch created successfully': 'Tranche créée avec succès',
          'Tranch updated successfully': 'Tranche mise à jour avec succès',
          'Tranch deleted successfully': 'Tranche supprimée avec succès',
          'Duplicate work Detail created': "Dupliquer le détail d'Ouvrage",
          'Duplicate work created': "Duppliquer l'Ouvrage",
          Empty: 'VIDE',
          'Generate Estimate': "Générer l'estimatif",
          'Generate The DQE': 'Générer le DQE',
          'Generate BPU': 'Générer le BPU',
          'Generate DPGF': 'Générer le DPGF',
          'Generate The CCTP': 'Générer le CCTP',
          'Technical Doc': 'Document Technique',
          'To Modify': 'Modifier',
          'To Delete': 'Supprimer',
          Duplicate: 'Duppliquer',
          Top: 'En haut',
          Up: 'Monter',
          Down: 'Descendre',
          Bottom: 'En bas',
          Create: 'Créer ',
          'Select Tranch': 'Sélectionnez une tranche',
          'Add New': 'Ajouter nouveau',
          'Type of stakeholder': "Type d'intervenant",
          'Name of stakeholder': "Nom de l'intervenant",
          'Tranch is required': 'La tranche est requise',
          'Select at least 1 work detail': 'Sélectionnez au moins 1 détail de travail',
          'Architect Stakeholders': "Maitre d'Ouvrage",
          'Buy Credits': 'Acheter des crédits',
          'Manage Trades': 'Gérer les lots',
          'Add Trade': 'Ajouter un lot',
          'Trade created successfully': 'Lot créé avec succès',
          'Trade updated successfully': 'Lot mis à jour avec succès',
          'Trade deleted successfully': 'Lot supprimé avec succès',
          'Project Name': 'Nom du projet',
          'Chapter selection': 'Sélection du Chapitre',
          'The last remaining Batch cannot be deleted.':
            'on ne peut supprimer le dernier Lot restant',
          'The last remaining Slice cannot be deleted.':
            'La dernière tranche restante ne peut pas être supprimée.',
          'Delete tranches first': 'Supprimer les tranches en premier',
          'Private Libraries': 'Bibliothèques Prescripteurs',
          Architect: 'Prescripteur',
          Home: 'Domicile',
          Management: 'Gestion',
          Profile: 'Profil',
          'Remaning Projects': 'Projets restants',
          'Valid Until': 'Valable jusque',
          'No technical document in this project': 'Pas de document technique dans ce projet',
          'To use the Quadrabase application': 'Pour utiliser l’application Quadrabase',
          'you must subscribe': 'vous devez vous abonner',
          'Buy a project pack': 'Acheter un pack projet',
          'Number of Projects': 'Nombre de projets',
          'Projects in progress': 'Projets en cours',
          'Projects remaining': 'Projets restants',
          'Project Complete': 'Projet terminé',
          'Please select a chap. 0 part 2 from properties':
            'Vous devez d’abord sélectionner un Chap0 Part2 dans les Propriétés du Projet',
          'Project Date': 'Date du projet',
          'No text': 'Pas de texte',
          'Update Trade': 'Mise à jour du lot',
          'Update Tranch': 'Mise à jour de la tranche',
          'Select a type of stakeholder': 'Sélectionnez un type d’Intervenant',
          'Name of the project': 'Nom du Projet',
          Owner: 'Maître d’ouvrage',
          'Date Of Creation': 'Date création',
          'Date Of Last Modification': 'Date modification',
          Status: 'Statut',
          'Type Of Stakeholder': 'Type d’Intervenant',
          'Subscribe to Quadrabase': 'S’abonner à Quadrabase',
          'Add Subscriptions': 'Ajouter des abonnements',
          Duration: 'Durée (mois)',
          'Duration Price': 'Prix extension abonnement',
          'Number of Projects': 'Nombre de projets',
          'Pack Price': 'Prix projets supplémentaires',
          'Family already in use': 'Un Chap0 est déjà assigné à cette famille',
          'This family does not have a Chap. 0 part 2': "Cette famille n'a pas de Chap. 0 partie 2",
          'Email Address': 'Adresse Email',
          Telephone: 'Téléphone',
          Products: 'Produits',
          'Add a product': 'Ajouter un produit',
          'Edit Product': 'Modifier le produit',
          'Add Level': 'Ajouter un niveau',
          Family: 'Domaine',
          'Modify the text of Chap0 Part2': 'Modifier le texte du Chap0 Part2',
          'Update Status': 'Mettre à jour le statut',
          'Prescriber Libraries': 'Bibliothèque Prescripteur',
          'Level created successfully': 'Ajouté avec succès',
          'Deleted successfully': 'Supprimé avec succès',
          TradesAndTranches: 'Lots et Tranches',
          Simple: 'Lot Simple',
          Rates: 'Tarifs',
          Rate: 'Tarif',
          subscription: 'abonnement',
          project: 'projet',
          Prescribers: 'Prescripteurs',
          'File Manager': 'Gestionnaire de fichiers',
          'Add a folder': 'Ajouter un dossier',
          Rename: 'Renommer',
          'Add a file': 'Ajouter un fichier',
          'Folder already exists': 'Le dossier existe déjà',
          'Folder created successfully': 'Dossier créé avec succès',
          "Parent folder doesn't exist": "Le dossier parent n'existe pas",
          'Subfolder already exists': 'Le sous-dossier existe déjà',
          'Subfolder created successfully': 'Sous-dossier créé avec succès',
          'Folder updated successfully': 'Dossier mis à jour avec succès',
          'File updated successfully': 'Fichier mis à jour avec succès',
          'File deleted successfully': 'Fichier supprimé avec succès',
          'File uploaded successfully': 'Fichier téléchargé avec succès',
          'No file to upload': 'Aucun fichier à télécharger',
          'Folders deleted successfully': 'Dossiers supprimés avec succès',
          Provider: 'Fabricant',
          'No works found for this provider.': 'Aucun ouvrage associé pour ce Fabricant.',
          'Rename folder': 'Renommer le dossier',
          'Modify status': 'Modifier le statut',
          'Type of Customer': 'Type de Client',
          'Price excl. VAT': 'Tarif H.T.',
          Warning: 'Avertissement',
          Continue: 'Continuer',
          'Add title': 'Ajouter un Titre',
          'Edit text': 'Modifier le texte'
        }
      }
    },

    fallbackLng: 'fr',
    debug: true,
    lng: 'fr',
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
