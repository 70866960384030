import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { WORK_DETAIL_STATE } from '../../../components/formik/initialValues';
import { WORK_YUP } from '../../../components/formik/validations';
import Axios from 'axios';
import { baseUrl } from '../../../utils/BaseUrl';
import { getToken } from '../../../utils/Auth';
import Notifier from '../../../utils/Notifier';
import Button from '../../../components/Button';
import TextInput from '../../../components/TextInput';
import { red } from '@mui/material/colors';
import SelectField from '../../../components/SelectField';
import { formatNumber } from '../../../utils/markThousand';

export default function AddExpense(props) {
  const token = getToken();
  const { translate } = props;
  const [options, setOptions] = React.useState([]);

  console.log('EDITT  SCREEN ');

  const [isLoading, setisLoading] = React.useState(false);
  const formik = useFormik({
    initialValues: WORK_DETAIL_STATE,
    validationSchema: WORK_YUP,
    onSubmit: async values => {
      setisLoading(true);
      values.unit_price = values.unit_price.replace(/\s/g, '').split(',').join('.');
      try {
        let response = await Axios({
          method: 'put',
          url: `${baseUrl}/BookLibraries/editWorkDetail/${props.libraryId}`,
          data: values,
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        Notifier(response.data.message, 'success', translate);
        props.getLevel4(props.editData.work);
        setisLoading(false);
        props.handleClose();
      } catch (err) {
        setisLoading(false);
        Notifier(err.response.data.message, 'error', translate);

        console.log('Errror->>>', err);
      }
    }
  });
  React.useEffect(() => {
    let editData = props.editData;
    let initialValues = formik.initialValues;

    if (editData && Object.keys(editData).length > 0) {
      Object.keys(editData).map((dat, i) => {
        if (Object.keys(initialValues).includes(dat)) {
          if (dat == 'unit_price') {
            formik.setFieldValue(
              'unit_price',
              formatNumber(parseFloat(editData[dat]).toFixed(2).toString(), '.')
            );
          } else {
            formik.setFieldValue(dat, editData[dat]);
          }
        }
      });
    }
  }, [props.editmodal]);
  const getAllUnits = async () => {
    try {
      let response = await Axios({
        method: 'get',
        url: `${baseUrl}/Units`,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setOptions(response.data.units.sort((a, b) => a.ranking - b.ranking));
    } catch (error) {
      console.log(error?.response?.data?.message, 'error ');
    }
  };
  useEffect(() => {
    getAllUnits();
  }, []);
  return (
    <form className="w-full  pt-9">
      <div className=" -mx-3 mb-2">
        <div className="w-full  px-3 mb-3">
          <TextInput
            type="text"
            label={translate('Name')}
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          <div className="text-red-600 ">
            {formik.touched.name && formik.errors.name ? <small>{formik.errors.name}</small> : null}
          </div>
        </div>
        <div className="w-full  px-3 mb-3">
          <TextInput
            type="text"
            label={translate('PU HT')}
            name="unit_price"
            onChange={formik.handleChange}
            value={formik.values.unit_price}
            onBlur={() => {
              let temp = formik.values.unit_price
                .toString()
                .replace(/\s/g, '')
                .split(',')
                .join('.');
              formik.setFieldValue(
                'unit_price',
                formatNumber(parseFloat(temp).toFixed(2).toString(), '.')
              );
            }}
            onFocus={() => {
              let temp = formik.values.unit_price
                .toString()
                .replace(/\s/g, '')
                .split(',')
                .join('.');
              formik.setFieldValue(
                'unit_price',
                formatNumber(parseFloat(temp).toFixed(2).toString(), '.')
              );
            }}
          />
          <div className="text-red-600 ">
            {formik.touched.unit_price && formik.errors.unit_price ? (
              <small>{formik.errors.unit_price}</small>
            ) : null}
          </div>
        </div>
        <div className="w-full  px-3 mb-3">
          <SelectField
            name="unit"
            label={translate('Select Unit')}
            value={formik.values.unit}
            onChange={formik.handleChange}
            options={options}
          />
          <div className="text-red-600 ">
            {formik.touched.unit && formik.errors.unit ? <small>{formik.errors.unit}</small> : null}
          </div>
        </div>
      </div>
      <div className="flex pb-2 justify-end ">
        <div className="">
          <Button
            onClick={() => props.handleClose()}
            label={translate('Close')}
            backgroundColor={red}
            default={false}
          />
        </div>
        <div className=" ml-3">
          <Button
            onClick={formik.handleSubmit}
            disabled={isLoading}
            label={translate('Update')}
            default={true}
          />
        </div>
      </div>
    </form>
  );
}
