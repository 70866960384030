// init
import React, { useState, useEffect } from "react";
import api from "../../../../api";
import { toast } from "react-toastify";
import { Store } from "../../../../StoreContext";
import { useTranslation } from "react-i18next";

// component
import InputWithLabel from "../../../../components/Architect/InputWithLabel";
import { useStyles } from "../../../../css/projectLibraries";
import { Button, Grid } from "@mui/material";
import TextEditor from "../../../../components/Architect/TextEditor";
import InputDropdown from "../../../../components/Architect/InputDropdown";
import { formatNumber } from "../../../../utils/markThousand";

// component
const AddWorkDetailsModal = ({ handleOpenAddWorkDetailModal, workDetailData, work }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  // states
  const [values, setValues] = useState({
    name: "",
    unit: "",
    unit_price: "0.00",
  });
  const [options, setOptions] = useState([]);

  // useEffect to get all units
  useEffect(() => {
    getUnits();
  }, []);

  // func to getAll Units
  const getUnits = async () => {
    const result = await api("get", `/Units/get`);
    setOptions(result.data.units.sort((a, b) => a.ranking - b.ranking));
  };

  // handle change function
  const handleChange = (name, value) => {
    setValues((prev) => {
      prev[name] = value;
      return { ...prev };
    });
  };

  // handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = values;
    data.workId = work;
    data.ranking = workDetailData ? workDetailData.length : 0;
    data.unit_price = values.unit_price.replace(/\s/g, "").split(",").join(".");
    const result = await api("post", `/BookLibraries/add/WorkDetail`, data, t);
    toast.success(t(result.data.message));
    handleOpenAddWorkDetailModal();
  };

  // jsx
  return (
    <form className={classes.smform} onSubmit={handleSubmit}>
      {/* All fields */}
      <br />
      <InputWithLabel
        label={t("Name")}
        required={true}
        name="name"
        type="text"
        value={values.name}
        handleChange={(e) => handleChange(e.target.name, e.target.value)}
      />
      <br />
      <br />
      <InputWithLabel
        name="unit_price"
        label="PU HT"
        value={values.unit_price}
        handleChange={(e) => handleChange(e.target.name, e.target.value)}
        required={true}
        onBlur={() => {
          let temp = values.unit_price.replace(/\s/g, "").split(",").join(".");
          setValues((prev) => {
            prev["unit_price"] = formatNumber(parseFloat(temp).toFixed(2).toString(), ".");
            return { ...prev };
          });
        }}
        onFocus={() => {
          let temp = values.unit_price.replace(/\s/g, "").split(",").join(".");
          setValues((prev) => {
            prev["unit_price"] = formatNumber(parseFloat(temp).toFixed(2).toString(), ".");
            return { ...prev };
          });
        }}
      />
      <br />
      <br />
      <InputDropdown
        name="unit"
        label={t("Unit")}
        value={values.unit}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
        options={options}
        required={true}
      />
      <br />
      {/* create button */}
      <div style={{ width: "100%", textAlign: "right" }}>
        <Button variant="contained" className={classes.btn} onClick={handleOpenAddWorkDetailModal}>
          {t("Close")}
        </Button>
        &nbsp;&nbsp;
        <Button variant="contained" className={classes.btn} type="submit">
          {t("Create")}
        </Button>
      </div>
    </form>
  );
};

export default AddWorkDetailsModal;
