export const ArchitectheadCells = [
  {
    id: 'establishment',

    label: 'Establishment'
  },
  {
    id: 'service',

    label: 'Service'
  },
  {
    id: 'address_1',

    label: 'Address 1'
  },
  {
    id: 'address_2',

    label: 'Address 2'
  },
  {
    id: 'po_box',

    label: 'PO Box'
  },
  {
    id: 'postal_code',

    label: 'Postal Code'
  },
  {
    id: 'city',

    label: 'City'
  },
  {
    id: 'email_address_client',

    label: 'Email Address (Client)'
  },
  {
    id: 'last_name',

    label: 'Last Name'
  },
  {
    id: 'first_name',

    label: 'First Name'
  },
  {
    id: 'phone',

    label: 'Phone'
  },
  {
    id: 'email',

    label: 'Email'
  },
  {
    id: 'client_code',

    label: 'Client Code'
  },
  {
    id: 'authorized',
    label: 'Authorize'
  },
  {
    id: 'subscription',
    label: 'Subscription'
  },
  {
    id: 'subscriptionStartDate',

    label: 'Subscription Start Date'
  },
  {
    id: 'subscriptionEndDate',

    label: 'Subscription End Date'
  },
  {
    id: 'total_projects',
    label: 'Total Projects'
  },
  {
    id: 'ongoing_projects',
    label: 'Ongoing Projects'
  },
  {
    id: 'remaining_projects',
    label: 'Remaining Projects'
  },
  {
    id: 'createdAt',

    label: 'Date'
  },
  {
    id: 'action',

    label: 'Action'
  }
];
export const AdminheadCells = [
  {
    id: 'last_name',

    label: 'Last Name'
  },
  {
    id: 'first_name',

    label: 'First Name'
  },

  {
    id: 'email',

    label: 'Email'
  },

  {
    id: 'createdAt',

    label: 'Date'
  },
  {
    id: 'action',

    label: 'Action'
  }
];

export const SupportheadCells = [
  {
    id: 'supportTitle',

    label: 'supportTitle'
  },
  {
    id: 'action',

    label: 'Action'
  }
];

export const ProductheadCells = [
  {
    id: 'product_type',

    label: 'Type of Customer'
  },
  {
    id: 'reference',

    label: 'Reference'
  },
  {
    id: 'name',

    label: 'Name'
  },

  {
    id: 'duration',

    label: 'Duration'
  },
  {
    id: 'projects',

    label: 'Projects'
  },
  {
    id: 'productPrice',

    label: 'Price excl. VAT'
  },

  {
    id: 'action',

    label: 'Action'
  }
];

export const FamiliesheadCells = [
  {
    id: 'name',

    label: 'Name'
  },
  {
    id: 'ranking',

    label: 'Ranking'
  },

  {
    id: 'createdAt',

    label: 'Date/'
  },
  {
    id: 'action',

    label: 'Action'
  }
];
export const UnitheadCells = [
  {
    id: 'name',

    label: 'Name'
  },
  {
    id: 'symbol',

    label: 'Symbol'
  },
  {
    id: 'ranking',

    label: 'Ranking'
  },

  {
    id: 'createdAt',

    label: 'Date'
  },
  {
    id: 'action',

    label: 'Action'
  }
];

export const VatheadCells = [
  {
    id: 'value',

    label: 'Value'
  },
  {
    id: 'symbol',

    label: 'Symbol'
  },
  {
    id: 'ranking',

    label: 'Ranking'
  },
  {
    id: 'createdAt',

    label: 'Date'
  },
  {
    id: 'action',

    label: 'Action'
  }
];
export const CctpheadCells = [
  {
    id: 'name',

    label: 'Name'
  },
  {
    id: 'type',

    label: 'Type'
  },
  {
    id: 'ranking',

    label: 'Ranking'
  },

  {
    id: 'createdAt',

    label: 'Date'
  },
  {
    id: 'action',

    label: 'Action'
  }
];
export const Chap0headCells = [
  {
    id: 'title',

    label: 'Title'
  },

  {
    id: 'createdAt',

    label: 'Date'
  },
  {
    id: 'action',

    label: 'Action'
  }
];

export const ProviderheadCells = [
  {
    id: 'establishment',

    label: 'Establishment'
  },
  {
    id: 'logo',

    label: 'Logo'
  },
  {
    id: 'address1',

    label: 'Address 1'
  },
  {
    id: 'address2',

    label: 'Address 2'
  },
  {
    id: 'po_box',

    label: 'PO Box'
  },
  {
    id: 'zipcode',

    label: 'ZipCode'
  },
  {
    id: 'city',

    label: 'City'
  },
  {
    id: 'email_address_client',

    label: 'Email Address (Client)'
  },
  {
    id: 'name',

    label: 'Name'
  },
  {
    id: 'firstname',

    label: 'Firstname'
  },

  {
    id: 'telephone_no',

    label: 'Telephone No.'
  },
  {
    id: 'email',

    label: 'Email'
  },
  {
    id: 'client_code',

    label: 'Client Code'
  },
  {
    id: 'authorized',
    label: 'Authorize'
  },
  {
    id: 'url',

    label: 'url'
  },
  {
    id: 'provider_subscription',
    label: 'Subscription'
  },
  {
    id: 'provider_subscriptionStartDate',

    label: 'Subscription Start Date'
  },
  {
    id: 'provider_subscriptionEndDate',

    label: 'Subscription End Date'
  },
  {
    id: 'createdAt',

    label: 'Date'
  },
  {
    id: 'action',

    label: 'Action'
  }
];

export const SubscriptionheadCells = [
  {
    id: 'code',

    label: 'Code'
  },
  {
    id: 'subscriptionName',

    label: 'Désignation'
  },
  {
    id: 'subscriptionStartDate',

    label: 'Sub Start Date'
  },
  {
    id: 'subscriptionEndDate',

    label: 'End Date'
  },
  {
    id: 'subStatus',

    label: 'Status'
  },
  {
    id: 'actions',

    label: 'Actions'
  }
];

export const ProviderSubscriptionheadCells = [
  {
    id: 'name',
    label: 'Name'
  },
  {
    id: 'start_date',
    label: 'Start Date'
  },
  {
    id: 'end_date',
    label: 'End Date'
  },
  {
    id: 'authorize',
    label: 'Authorize'
  }
];

export const MyProjectHeadCells = [
  {
    id: 'totalProjects',

    label: 'Number of Projects'
  },
  {
    id: 'inProgress',

    label: 'Projects in progress'
  },
  {
    id: 'remaining',

    label: 'Project Complete'
  },
   {
    id: 'deleted',

    label: 'Projets supprimés'
  }
];

export const StakeholderheadCells = [
  {
    id: 'type',

    label: 'Type Of Stakeholder'
  },
  {
    id: 'establishment',

    label: 'Establishment'
  },
  {
    id: 'service',

    label: 'Service'
  },
  {
    id: 'address1',

    label: 'Address 1'
  },
  {
    id: 'address2',

    label: 'Address 2'
  },
  {
    id: 'po_box',
    label: 'BP/CS'
  },
  {
    id: 'zipcode',

    label: 'CP'
  },
  {
    id: 'city',

    label: 'City'
  },
  {
    id: 'lastname',

    label: 'Lastname'
  },
  {
    id: 'firstname',
    label: 'Firstname'
  },

  {
    id: 'contact_no',
    label: 'Telephone'
  },

  {
    id: 'email',
    label: 'Email Address'
  },

  {
    id: 'actions',

    label: 'Actions'
  }
];

export const Chap0Part2headCells = [
  {
    id: 'chapTitle',

    label: 'Title'
  },
  {
    id: 'actions',

    label: 'Actions'
  }
];
export const ProjectheadCells = [
  {
    id: 'projectName',

    label: 'Name of the project'
  },
  {
    id: 'client',

    label: 'Owner'
  },
  {
    id: 'market_type',

    label: 'Type Of Contract'
  },
  {
    id: 'createdAt',

    label: 'Date Of Creation'
  },
  {
    id: 'updatedAt',

    label: 'Date Of Last Modification'
  },
  {
    id: 'projectStatus',

    label: 'Status'
  },

  {
    id: 'actions',

    label: 'Actions'
  }
];
