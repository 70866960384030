// Init
import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";

import { useHistory } from "react-router-dom";

// Component
export default function Header(props) {
  const history = useHistory();

  const navToRegister = () => {
    history.push("/auth/register");
  };

  return (
    <div className="spc_container">
      <Typography className="spc_Heroheading">
        Prescripteurs, gagnez <br />
        en productivité !
      </Typography>
      <Typography className="spc_Heropara">
        Maîtres d'ouvrage, Maîtres d'Oeuvre, Architectes,
        <br />
        Bureaux d'études, Paysagistes-concepteurs,
        <br />
        Géomètres-Experts.
      </Typography>
      <div className="spc_btndiv">
        <Button
          className="spc_herobtn1"
          sx={{ marginRight: "1%" }}
          onClick={props.handleScroll}
        >
          En savoir plus
        </Button>
        <Button className="spc_herobtn2" onClick={navToRegister}>
          S'inscrire
        </Button>
      </div>
    </div>
  );
}
