import React from "react";

import TextInput from "../../../../components/TextInput";

export default function AddExpense(props) {
  const { translate, formik } = props;

  React.useEffect(() => {
    let editData = props.editData;
    let initialValues = formik.initialValues;

    if (editData && Object.keys(editData).length > 0) {
      Object.keys(editData).map((dat, i) => {
        if (Object.keys(initialValues).includes(dat)) {
          formik.setFieldValue(dat, editData[dat]);
        }
      });
    }
  }, [props.editmodal]);
  return (
    <form className="w-full pt-9">
      <div className="flex no-wrap -mx-3 mb-2">
        <div className="px-3 mb-6 md:mb-0" style={{ width: "40%" }}>
          <TextInput
            type="text"
            label={translate("Name")}
            labelUpperCase={true}
            name="name"
            translate={translate}
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          <div className="text-red-600 ">
            {formik.touched.name && formik.errors.name ? (
              <small>{formik.errors.name}</small>
            ) : null}
          </div>
        </div>
        <div className="px-3" style={{ width: "60%" }}>
          <TextInput
            type="text"
            placeholder="Title"
            label="Title"
            labelUpperCase={true}
            name="type"
            translate={translate}
            onChange={formik.handleChange}
            value={formik.values.type.toUpperCase()}
          />
          <div className="text-red-600 ">
            {formik.touched.type && formik.errors.type ? (
              <small>{formik.errors.type}</small>
            ) : null}
          </div>
        </div>
      </div>
    </form>
  );
}
