// init
import { makeStyles } from "@mui/styles";

// makeStyles from material UI for styling
export const useStyles = makeStyles({
  container: {
    width: "100%",
    padding: 20,
  },
  left_container: {
    padding: 10,
  },
  right_container: {
    padding: 10,
  },
  flex_container: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  },
});
