const actionTypes = {
  LEVEL_0: "level0",
  LEVEL_1: "level1",
  LEVEL_2: "level2",
  LEVEL_3: "level3",
  LEVEL_4: "level4",
  anchorEl: "anchorEl",
  MANAGE_FAMILIES: "Manage_Families_level0",

  ADD_LIBRARY: "Add_Library_level0",
  EDIT_LIBRARY: "Edit_level1",
  DELETE_LIBRARY: "Delete_level1",

  CLOSE_MODAL: "Close_Modal",
  ADD_CATEGORY: "Add_Category_level1",
  EDIT_CATEGORY: "Edit_level2",
  DELETE_CATEGORY: "Delete_level2",
  ADD_WORK: "Add_Work_level2",
  EDIT_WORK: "Edit_level3",
  DELETE_WORK: "Delete_level3",
  ADD_WORK_DETAIL: "Add_Work_Detail_level3",
  EDIT_WORK_DETAIL: "Edit_level4",
  DELETE_WORK_DETAIL: "Delete_level4",

  // up & down
  LIBRARY_UP: "Up_level1",
  LIBRARY_DOWN: "LIBRARY_DOWN",

  // CCTP level
  Chap0Level1: "Chap0Level1",
  Chap0Level2: "Chap0Level2",
  Chap0Level3: "Chap0Level3",
  Chap0AnchorEl: "Chap0AnchorEl",

  // Level1
  AddChap0Level1: "AddChap0Level1",
  EditChap0Level1: "EditChap0Level1",
  DeleteChap0Level1: "DeleteChap0Level1",

  // Level2
  AddChap0Level2: "AddChap0Level2",
  EditChap0Level2: "EditChap0Level2",
  DeleteChap0Level2: "DeleteChap0Level2",

  // Level3
  AddChap0Level3: "AddChap0Level3",
  EditChap0Level3: "EditChap0Level3",
  DeleteChap0Level3: "DeleteChap0Level3",
};
export default actionTypes;
