export const initialState = {
  level0: {},
  level1: {},
  level2: {},
  level3: {},
  anchorEl: null,

  //family
  managefamily: false,

  // library
  addlibrary: false,
  editlibrary: false,
  deletelibrary: false,
  libraryDeleteId: "",
  editlibraryId: "",

  // category
  addcategory: false,
  editcategory: false,
  deletecategory: false,

  categoryDeleteId: "",
  editcategoryId: "",
  editLibraryData: "",

  //work
  addwork: false,
  editwork: false,
  deletework: false,
  workDeleteId: "",
  editworkId: "",
  editWorkData: "",
  //work detail
  addworkdetail: false,
  editworkdetail: false,
  deleteworkdetail: false,
  workDetailDeleteId: "",
  editworkdetailId: "",
  editWorkDetailData: "",

  // CCTP level
  Chap0Level1: {},
  Chap0Level2: {},
  Chap0Level3: {},
  Chap0AnchorEl: null,

  // Level1
  AddChap0Level1: false,
  EditChap0Level1: false,
  DeleteChap0Level1: false,
  AddChap0Level1Id: "",
  EditChap0Level1Id: "",
  DeleteChap0Level1Id: "",

  // Level2
  AddChap0Level2: false,
  EditChap0Level2: false,
  DeleteChap0Level2: false,
  AddChap0Level2Id: "",
  EditChap0Level2Id: "",
  DeleteChap0Level2Id: "",

  // Level3
  AddChap0Level3: false,
  EditChap0Level3: false,
  DeleteChap0Level3: false,
  AddChap0Level3Id: "",
  EditChap0Level3Id: "",
  DeleteChap0Level3Id: "",
};
