const htmlTagRegex = /<\/?[^>]+>/gi;

const cctpArr = [
  'Chapitre 0 – CLAUSES GENERALES',
  'Chapitre 1 – DOCUMENTS DE REFERENCE CONTRACTUELS',
  'Chapitre 2 – PROVENANCES ET SPECIFICATIONS DES MATERIAUX ET PRODUITS',
  'Chapitre 3 – MODE D’EXECUTION DES TRAVAUX ET PRESTATIONS',
  'Chapitre 4 – ESSAIS – CONTRÔLES – RECEPTION'
];

export { htmlTagRegex, cctpArr };
