import React from "react";
import Header from "../../../components/Architect/Header";

const Success = () => {
  return (
    <>
      <Header />
      <h1 style={{ textAlign: "center", width: "100vw", color: "black" }}>
        Payment Successful!
      </h1>
    </>
  );
};

export default Success;
