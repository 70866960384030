import { Paragraph, TextRun, HeadingLevel, AlignmentType } from 'docx';

const l4Font = 16 * 2;
const l3Font = 14 * 2;
const l2Font = 12 * 2;
const l1Font = 11 * 2;
const l0Font = 10 * 2;
const ln1Font = 9 * 2;

const fontType = 'Calibri';

function docText({
  text,
  linebreaks,
  size,
  heading,
  bold = false,
  font = fontType,
  center = false,
  spacing,
  shading,
  italics = false
}) {
  return new Paragraph({
    alignment: !center ? AlignmentType.START : AlignmentType.CENTER,
    children: text.split('\n').map(
      line =>
        new TextRun({
          text: line.replace(/\&gt;/g, '>').replace(/\&lt;/g, '<'),
          break: linebreaks,
          size: size,
          bold: bold,
          font: font,
          italics: italics,
          color: '000000'
        })
    ),
    heading: heading ?? null,
    spacing: spacing,
    shading: shading
  });
}

function l4Text(text, linebreaks, spacing, shading) {
  return docText({
    text: text,
    linebreaks: linebreaks,
    size: l4Font,
    heading: HeadingLevel.HEADING_1,
    bold: true,
    spacing: spacing,
    shading: shading
  });
}

function l3Text(text, linebreaks, spacing) {
  return docText({
    text: text,
    linebreaks: linebreaks,
    size: l3Font,
    heading: HeadingLevel.HEADING_2,
    bold: true,
    spacing: spacing
  });
}

function l2Text(text, linebreaks, spacing) {
  return docText({
    text: text,
    linebreaks: linebreaks,
    size: l2Font,
    heading: HeadingLevel.HEADING_3,
    bold: true,
    spacing: spacing
  });
}

function l1Text(text, bold = false, linebreaks, spacing) {
  return docText({
    text: text,
    linebreaks: linebreaks,
    size: l1Font,
    bold: bold,
    spacing: spacing
  });
}

function l0Text(
  text,
  bold = false,
  center = false,
  linebreaks,
  spacing,
  italics = false,
  font = l0Font
) {
  return docText({
    text: text,
    linebreaks: linebreaks,
    size: font,
    bold: bold,
    font: fontType,
    center: center,
    spacing: spacing,
    italics: italics
  });
}

function ln1Text(text, bold = false, linebreaks, spacing) {
  return docText({
    text: text,
    linebreaks: linebreaks,
    size: ln1Font,
    bold: bold,
    spacing: spacing
  });
}

function lineBreak() {
  return new Paragraph({
    text: ''
  });
}

export {
  l4Font,
  l3Font,
  l2Font,
  l1Font,
  l0Font,
  ln1Font,
  fontType,
  docText,
  l4Text,
  l3Text,
  l2Text,
  l1Text,
  l0Text,
  ln1Text,
  lineBreak
};
