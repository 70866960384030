// init
import { makeStyles } from "@mui/styles";

// makeStyles from material UI for styling
export const useStyles = makeStyles({
  form: {
    marginTop: "auto",
    marginBottom: "auto",
    width: 650,
    maxHeight: "80vh",
    overflow: "auto",
  },
  container: {
    width: "100%",
  },
  btn: {
    backgroundColor: "#8C1244 !important",
    borderRadius: 10,
  },
});
